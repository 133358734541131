import React, { useEffect } from 'react';
import CustomTitle from '../components/UI/title/CustomTitle';

const Revenue = ({ setButtonShow, setButtonText, setButtonChoose,setSelectedOption, setButtonTime, setButtonStatus, setBlockPaddingBottom, setAiShow, setEventProperties, sessionId, setEventName}) => {
    useEffect(() => {
        setButtonText('Continue')
        setButtonShow(true);
        setButtonChoose(false);
        setSelectedOption('1')
        setButtonTime(150)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setAiShow(false)
        setEventName('web_mixing')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <>

            <CustomTitle title="See how mixing images & videos in ads can boost your revenue" />

            <p style={{ marginBottom: "24rem" }} className="text">*The data is based on the results of our users</p>
            <div style={{ aspectRatio: "303/167" }}>
                <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/img/revenue.svg'} alt="" />
            </div>

            <div style={{ paddingBottom: "32rem" }}></div>
        </>
    );
}

export default Revenue;
