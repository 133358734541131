import React, { useState, useEffect, useRef } from 'react';
import CustomTitle from '../../components/UI/title/CustomTitle';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import './signup.scss';
import CustomCheckbox from '../../components/UI/chekbox/CustomCheckbox';
import Term from '../Term';
import Privacy from '../Privacy';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
const Signup = ({ setLoginChangeStatus, setSelectedOption, setButtonText, setButtonShow, setBlockPaddingBottom, setSignupBottom, signupBottom, buttonStatus, setButtonStatus, error, setError, forgetPassword, setTimeLeft, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();
    useEffect(() => {

        setButtonText('Continue');
        setButtonShow(true);
        setLoginChangeStatus(true)
        setSignupBottom('checkbox');
        setButtonStatus('checkEmail');
        setSelectedOption('1')
    }, []);

    const [title, setTitle] = useState('Enter your email<br/>to get your AI-driven promotion tools!');

    const [inputEmail, setInputEmail] = useState('');
    const [inputEmail2, setInputEmail2] = useState('');
    const [errorEmail1, setErrorEmail1] = useState(false);
    const [msgEmail1, setMsgEmail1] = useState('');
    const [errorEmail2, setErrorEmail2] = useState(false);
    const [msgEmail2, setMsgEmail2] = useState('');
    const [inputPassword, setInputPassword] = useState('');
    const [placeholderPassword, setPlaceholderPassword] = useState('');
    const [errorPassword, setErrorPassword] = useState(false);
    const [msgPassword, setMsgPassword] = useState('');
    const [inputPassword2, setInputPassword2] = useState('');
    const [errorPassword2, setErrorPassword2] = useState(false);
    const [msgPassword2, setMsgPassword2] = useState('');


    const [showSecondInput, setShowSecondInput] = useState(false);
    const [showPasswordInput, setShowPasswordInput] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2Input, setShowPassword2Input] = useState(false);

    const [disabled, setDisabled] = useState(false);
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;


    const inputRefs = useRef([]);
    const [code, setCode] = useState(Array(6).fill(''));

    const handlePaste = (e) => {
        e.preventDefault();
        const pastedData = e.clipboardData.getData('text').trim().slice(0, 6);
        if (pastedData.length === inputRefs.current.length) {

            const splitData = pastedData.split('');
            setCode(splitData);
            const lastInputField = inputRefs.current[inputRefs.current.length - 1];
            if (lastInputField) {
                lastInputField.focus();
            }
        }
    };

    const handleChangeCode = (index) => (e) => {
        const newCode = [...code];
        const { value } = e.target;

        if (value === '' && newCode[index] === '' && index > 0) {
            inputRefs.current[index - 1].focus();
        }

        newCode[index] = value.toUpperCase().replace(/[^A-Z0-9]/g, '') || '';
        setCode(newCode);

        if (value && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }

    };

    const handleKeyDown = (index) => (e) => {
        if (e.key === 'Backspace' && !code[index] && index > 0) {
            const newCode = [...code];
            newCode[index - 1] = '';
            setCode(newCode);
            inputRefs.current[index - 1].focus();
        }
    };

    const isFormComplete = () => {
        return code.every(digit => digit.trim() !== '');
    };

    const [backSignUp, setBackSignUp] = useState(false);

    useEffect(() => {

        if (buttonStatus === 'loginChange') {
            // checkEmail()
            setBackSignUp(true)
            setTitle('Log in to Zeely');
            setButtonStatus('signIn');
            // setDisabled(true);
            setButtonText("Log in");
            setShowPasswordInput(true);
            setSignupBottom("loginChange");
            setPlaceholderPassword('Enter your password ');
            setLoginChangeStatus(false)
            mixpanel.track('web_signup', {
                session_id: sessionId,
                web_entrance: 'login',
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
                funnel: 'MAIN_v1',
            });
        }

        if (buttonStatus === 'checkEmailLoad') {
            if (!emailRegex.test(inputEmail)) {
                setButtonStatus('checkEmail');
                setSelectedOption(null);
                setTimeout(() => {
                    setSelectedOption('');
                    setError(false);
                }, 1000);
            } else {
                setError(false);
                setButtonStatus('load');
                checkEmail();
            }
        }
        if (buttonStatus === 'signInLoad') {
            if (inputPassword.trim() === '') {
                setButtonStatus('signIn');
                setErrorPassword(true);
                setMsgPassword('Please create a password')

            } else {
                setButtonStatus('load');
                logIn();
            }
        }
        if (buttonStatus === 'signUpLoad') {

            if (inputEmail.trim() !== inputEmail2.trim()) {
                setButtonStatus('signUp');
                setErrorEmail2(true);
                setMsgEmail2('These emails do not match, please try again')
                return;
            }

            const minLength = 8;


            if (inputPassword.trim() === '') {
                setButtonStatus('signUp');
                setErrorPassword(true);
                setMsgPassword('Please create a password');
                return;
            } else if (inputPassword.trim().length < minLength) {
                setButtonStatus('signUp');
                setErrorPassword(true);
                setMsgPassword(`Password must be between ${minLength} characters`);
                return;
            } else {
                setButtonStatus('load');
                signUp();
            }
        }
        if (buttonStatus === 'EnterEmail') {
            setTitle('Enter your mail');
            setButtonText("Continue");
            setSignupBottom('EnterEmail')
            setShowPasswordInput(false);
            setBackSignUp(true);
            setDisabled(false)
            mixpanel.track('web_click_forgotpassword', {
                session_id: sessionId,
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
                funnel: 'MAIN_v1',
            });
        }
        if (buttonStatus === 'EnterEmailLoad') {
            if (!emailRegex.test(inputEmail)) {
                setButtonStatus('checkEmail');
                setSelectedOption(null);
                setTimeout(() => {
                    setSelectedOption('');
                    setError(false);
                }, 1000);
            } else {
                setError(false);
                setTimeLeft(30)
                forgetPassword()
                setButtonStatus('EnterCode');

                // checkEmail();
            }
        }
        if (buttonStatus === 'EnterCode') {
            setTitle('Enter your code');
            setButtonText("Continue");
            setSignupBottom('EnterCode')
        }
        if (buttonStatus === "EnterCodeLoad") {
            if (isFormComplete()) {
                setButtonStatus('load');
                verifyCode(code.join(''))

            } else {
                setButtonStatus("EnterCode");
            }
        }
        if (buttonStatus === "NewPasswordLoad") {

            if (inputPassword.trim() === '') {
                setButtonStatus("NewPassword");
                setErrorPassword(true);
                setMsgPassword('Sorry, these emails do not match. Please try again ')
                return;
            }
            if (inputPassword.trim() !== inputPassword2.trim()) {
                setButtonStatus("NewPassword");
                setErrorPassword2(true);
                setMsgPassword2('Sorry, these emails do not match. Please try again r')
                return;
            } else {
                setButtonStatus('load');
                changePassword(code.join(''))
            }
        }


    }, [buttonStatus]);

    const checkEmail = () => {
        const data = JSON.stringify({
            "email": inputEmail,
        });
        const config = {
            method: 'post',
            url: 'https://staging.zeely.link/users/email-check',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(config)
            .then(response => {
                const isRegistered = response.data.data.isRegistered;
                mixpanel.track('web_signup', {
                    session_id: sessionId,
                    web_entrance: 'signup',
                    web_utm_campaign: localStorage.getItem("utmCampaign"),
                    web_utm_source: localStorage.getItem("utmSource"),
                    web_utm_medium: localStorage.getItem("utmMedium"),
                    web_utm_content: localStorage.getItem("utmContent"),
                    web_utm_term: localStorage.getItem("utmTerm"),
                    isDebug: false,
                    funnel: 'MAIN_v1',
                });
                if (buttonStatus === 'loginChange') {

                    if (isRegistered && inputEmail.trim() != '') {
                        setTitle('Log in to Zeely');
                        setButtonStatus('signIn');
                        setDisabled(true);
                        setButtonText("Log in");
                        setShowPasswordInput(true);
                        setSignupBottom("forget");
                        setPlaceholderPassword('Enter your password ');
                        setLoginChangeStatus(false)
                    } else {

                        setError(true);
                        setErrorEmail1(true);
                        setMsgEmail1("These emails do not match, please try again");

                        setTimeout(() => {
                            setButtonStatus('checkEmail');
                            setErrorEmail1(false);
                            setError(false);
                        }, 1000);
                    }
                } else {
                    if (isRegistered) {
                        setTitle('Log in to Zeely');
                        setButtonStatus('signIn');
                        setDisabled(true);
                        setButtonText("Log in");
                        setShowPasswordInput(true);
                        setSignupBottom("forget");
                        setPlaceholderPassword('Enter your password ');
                    } else {
                        setTitle('Confirm your email and create password');
                        setButtonStatus('signUp');
                        setButtonText("Continue");
                        setShowSecondInput(true);
                        setDisabled(true);
                        setShowPasswordInput(true);
                        setPlaceholderPassword('Create your password');
                        setLoginChangeStatus(false)
                        setSelectedOption(null)
                    }
                }

            })
            .catch(error => {
                // Handle the error
            });
    };

    const logIn = () => {
        const data = {
            email: inputEmail,
            password: inputPassword,
            os: 'web'
        };

        axios.post("https://staging.zeely.link/users/login", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)
                if (response.data.statusCode === 400 && response.data.message === 'Password is wrong') {
                    setButtonStatus('signIn');
                    setErrorPassword(true);
                    setMsgPassword('Sorry, you entered the wrong password. Please try again.')
                } else {
                    localStorage.setItem("accessToken", response.data.data.accessToken);
                    localStorage.setItem('email', inputEmail);
                    localStorage.setItem('signupMethod', 'Login');

                    businessProfiles()
                    sendUtm()
                    sendData()
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        'event': 'login',
                        'method': 'method',
                        'userId': response.data.data.id
                    });
                    checkSubscribe()
                    // history.push('/name');
                }
            })
            .catch(error => {
                setErrorPassword(true);
                setMsgPassword('Sorry, you entered the wrong password. Please try again.')
                setButtonStatus('signIn');
            });
    };

    const checkSubscribe = () => {

        axios.get("https://staging.zeely.link/subscriptions/current", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response.data.data.status)


                if (response.data.data.status === 'ACTIVE' || response.data.data.status === 'REDEMPTION') {
                    history.push('/thankyou');
                    localStorage.setItem('userId', response.data.data.userId)
                    mixpanel.track('web_loggedIn', {
                        session_id: sessionId,
                        web_entrance: 'login',
                        user_id: response.data.data.userId,
                        web_utm_campaign: localStorage.getItem("utmCampaign"),
                        web_utm_source: localStorage.getItem("utmSource"),
                        web_utm_medium: localStorage.getItem("utmMedium"),
                        web_utm_content: localStorage.getItem("utmContent"),
                        web_utm_term: localStorage.getItem("utmTerm"),
                        isDebug: false,
                        funnel: 'MAIN_v1',
                    });
                } else {
                    checkName()
                    // history.push('/name');
                }
            })
            .catch(error => {
                checkName()

            });
    }

    const checkName = () => {

        axios.get("https://staging.zeely.link/users", {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                localStorage.setItem('userId', response.data.data.id)
                mixpanel.track('web_loggedIn', {
                    session_id: sessionId,
                    web_entrance: 'login',
                    user_id: response.data.data.id,
                    web_utm_campaign: localStorage.getItem("utmCampaign"),
                    web_utm_source: localStorage.getItem("utmSource"),
                    web_utm_medium: localStorage.getItem("utmMedium"),
                    web_utm_content: localStorage.getItem("utmContent"),
                    web_utm_term: localStorage.getItem("utmTerm"),
                    isDebug: false,
                    funnel: 'MAIN_v1',
                });
                if (response.data.data.name && response.data.data.name.length) {
                    history.push('/prediction');
                } else {
                    history.push('/name');
                }
            })
            .catch(error => {
                // history.push('/name');
            });
    }




    const verifyCode = (passwordCode) => {
        const data = {
            email: inputEmail,
            "code": passwordCode,
        };

        axios.post("https://staging.zeely.link/users/verify-code", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)
                if (response.data.statusCode === 400 && response.data.message === 'Wrong email verification code provided') {
                    console.log('12')
                    setButtonStatus("EnterCode");
                    setSelectedOption(null);
                    setError(true)

                } else {
                    setInputPassword('')
                    setButtonStatus("NewPassword");
                    setSignupBottom('checkbox');
                    setShowPasswordInput(true);
                    setShowPassword2Input(true);
                    setTitle('Confirm your email and create password');

                    setButtonText('Continue');
                    setPlaceholderPassword('Create new password');
                    setLoginChangeStatus(false)
                    setDisabled(true);
                    mixpanel.track('web_click_entercode', {
                        session_id: sessionId,
                        web_entrance: 'login',
                        web_utm_campaign: localStorage.getItem("utmCampaign"),
                        web_utm_source: localStorage.getItem("utmSource"),
                        web_utm_medium: localStorage.getItem("utmMedium"),
                        web_utm_content: localStorage.getItem("utmContent"),
                        web_utm_term: localStorage.getItem("utmTerm"),
                        isDebug: false,
                        funnel: 'MAIN_v1',
                    });
                }
            })
            .catch(error => {
                console.log(error)
                setError(true);
                setSelectedOption(null);
                setTimeout(() => {
                    setSelectedOption('');
                    setError(false);
                }, 1000);
                setButtonStatus("EnterCode");
            });
    };


    const signUp = () => {
        const data = {
            email: inputEmail,
            password: inputPassword,
            os: 'web'
        };

        axios.post("https://staging.zeely.link/users/register", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)


                if (response.data.statusCode === 400 && response.data.message === 'User already exist') {
                    setButtonStatus('signUp');
                } else {
                    mixpanel.track('web_signup_confirm', {
                        session_id: sessionId,
                        web_entrance: "signup",
                        web_utm_campaign: localStorage.getItem("utmCampaign"),
                        web_utm_source: localStorage.getItem("utmSource"),
                        web_utm_medium: localStorage.getItem("utmMedium"),
                        web_utm_content: localStorage.getItem("utmContent"),
                        web_utm_term: localStorage.getItem("utmTerm"),
                        isDebug: false,
                        funnel: 'MAIN_v1',
                    });
                    localStorage.setItem("accessToken", response.data.data.accessToken);
                    localStorage.setItem('email', inputEmail);
                    businessProfiles();
                    sendUtm()
                    sendData()
                    axios.get("https://staging.zeely.link/users", {
                        headers: {
                            "Content-Type": "application/json",
                            'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                        },
                        redirect: 'follow'
                    })
                        .then(response => {
                            localStorage.setItem('userId', response.data.data.id)
                            window.dataLayer = window.dataLayer || [];
                            window.dataLayer.push({
                                'event': 'sign_up',
                                'method': 'method',
                                'userId': response.data.data.id
                            });

                            history.push('/name');
                        })
                        .catch(error => {
                            // history.push('/name');
                        });
                    // history.push('/name');
                }
            })
            .catch(error => {
                setButtonStatus('signUp');
            });
    };

    const changePassword = (passwordCode) => {
        const data = {
            email: inputEmail,
            "code": passwordCode,
            "newPassword": inputPassword,
        };

        axios.post("https://staging.zeely.link/users/change-password-by-code", data, {
            headers: {
                "Content-Type": "application/json"
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)

                logIn();
            })
            .catch(error => {
                setButtonStatus("NewPassword");
            });
    };

    const sendData = async () => {
        const formdata = new FormData();
        formdata.append("domen", window.location.hostname);
        formdata.append("email", inputEmail);
        formdata.append("utmSource", localStorage.getItem("utmSource"));
        formdata.append("utmMedium", localStorage.getItem("utmMedium"));
        formdata.append("utmCampaign", localStorage.getItem("utmCampaign"));
        formdata.append("utmContent", localStorage.getItem("utmContent"));
        formdata.append("utmTerm", localStorage.getItem("utmTerm"));
        try {
            const response = await axios.post("https://zee.sale/send-1-ai.php", formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            console.log(response.data);
        } catch (error) {
            console.error(error);
        }
    };


    const sendUtm = () => {
        const data = {
            "utmSource": localStorage.getItem("utmSource") || "default_source",
            "utmMedium": localStorage.getItem("utmMedium") || "default_medium",
            "utmCampaign": localStorage.getItem("utmCampaign") || "default_campaign",
            "utmTerm": localStorage.getItem("utmTerm") || "default_term",
            "utmContent": localStorage.getItem("utmContent") || "default_content",
            "url": window.location.hostname
        };
        axios.post("https://staging.zeely.link/users/utm", data, {
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
            },
            redirect: 'follow'
        })
            .then(response => {
                console.log(response)

            })
            .catch(error => {
                console.log(error)
            });
    }


    const businessProfiles = () => {
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.email = inputEmail;
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                email: inputEmail
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        // const fullJSON = localStorage.getItem('business-profiles');
        // const profile = JSON.parse(fullJSON)[0];
        // const trimmedJSON = JSON.stringify(profile);

        // const data = trimmedJSON

        // axios.post("https://staging.zeely.link/business-profiles", data, {
        //     headers: {
        //         "Content-Type": "application/json",
        //         'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
        //     },
        //     redirect: 'follow'
        // })
        //     .then(response => {
        //         localStorage.setItem('business-profiles-id', response.data.data.id);
        //     })
        //     .catch(error => {
        //         console.log(error)
        //     });
    };



    const handleChange = () => {
        setButtonStatus('checkEmail');
        setShowSecondInput(false);
        setDisabled(false);
        setShowPasswordInput(false);
        setButtonText('Continue');
        setTitle('Enter your email to get your AI-driven promotion tools!');
        setSignupBottom("checkbox");
        setLoginChangeStatus(true)
        setBackSignUp(false)
        setShowPassword2Input(false);
        setSelectedOption('1')
    }

    const handlePasswordChange = (e) => {
        setErrorPassword(false);
        if (e.target.value.length > 7) {
            setSelectedOption('1')
        } else {
            if (buttonStatus == 'signUp') {
                setSelectedOption(null)
            }

        }
        setInputPassword(e.target.value);
    };
    const handlePassword2Change = (e) => {
        setErrorPassword2(false);
        setInputPassword2(e.target.value);
    };
    const handleEmailChange = (e) => {
        setErrorEmail2(false);
        setInputEmail2(e.target.value.toLowerCase());
    };
    return (
        <div style={{ paddingBottom: "16rem" }}>
            {backSignUp && (
                <div className="back-signup" onClick={handleChange} style={{ marginBottom: '10rem', marginLeft: '-20rem', cursor: 'pointer' }}>
                    <svg style={{ width: '46rem', height: '46rem' }} width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M25.75 17L20.25 22.8182L25.75 29" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                    </svg>
                </div>
            )}



            <CustomTitle style={{ marginBottom: "24rem" }} title={title} />

            {(signupBottom == "checkbox" && !showPassword2Input) && (
                <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem' }}>
                    Just a few more steps and you're done.<br />
                    We hate paperwork, too
                </p>
            )}

            {signupBottom == 'loginChange' && (

                <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem' }}>
                    Please enter your email and password to log<br />into your account
                </p>
            )}
            {/* {signupBottom == 'forget' && (

                <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem' }}>
                    This email is already in use. If it's your account, please enter your password
                </p>
            )} */}

            {signupBottom == 'EnterEmail' && (

                <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem' }}>
                    Please enter your email and we'll send you
                    a recovery code
                </p>
            )}

            {signupBottom == "EnterCode" ? (
                <>
                    <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem', }}>
                        We sent a 6-digit code to you at {inputEmail}
                    </p>
                    <div className="code-inputs">
                        {code.map((digit, index) => (
                            <input
                                key={index}
                                type="text"
                                maxLength="1"
                                value={digit}
                                onKeyDown={handleKeyDown(index)}
                                onChange={handleChangeCode(index)}
                                onPaste={handlePaste}
                                ref={(el) => inputRefs.current[index] = el}
                                className="form__input"
                            />
                        ))}
                    </div>
                    {error && (
                        <div className="error-text">
                            Sorry, you entered the wrong code. Please try again.
                        </div>
                    )}
                </>
            ) : (
                <>
                    {showPassword2Input && (
                        <p className="text" style={{ marginTop: "-12rem", marginBottom: '24rem' }}>Just one more step and you'll get access to your Al-powered sales plan</p>
                    )}
                    <div className={`input ${error ? 'error' : ''} ${disabled ? 'disabled' : ''}`}>
                        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.80569 3.35547L14.412 3.35674C15.5369 3.37038 16.1238 3.49595 16.7325 3.82151C17.3226 4.13709 17.7899 4.60446 18.1055 5.19455C18.4514 5.84132 18.5716 6.46345 18.5716 7.73245V13.2642C18.5716 14.5332 18.4514 15.1553 18.1055 15.8021C17.7899 16.3922 17.3226 16.8596 16.7325 17.1751C16.0857 17.521 15.4636 17.6412 14.1946 17.6412H5.80569C4.53669 17.6412 3.91456 17.521 3.26779 17.1751C2.6777 16.8596 2.21033 16.3922 1.89475 15.8021C1.54885 15.1553 1.42871 14.5332 1.42871 13.2642L1.42998 7.51508C1.44363 6.39017 1.5692 5.80328 1.89475 5.19455C2.21033 4.60446 2.6777 4.13709 3.26779 3.82151C3.91456 3.47561 4.53669 3.35547 5.80569 3.35547ZM16.9195 6.01834L12.0204 10.9167C10.9419 11.9953 9.21545 12.0313 8.09367 11.0246L7.97983 10.9167L3.08073 6.01834C2.91686 6.38603 2.85728 6.80661 2.85728 7.73245V13.2642C2.85728 14.3175 2.9344 14.7169 3.15448 15.1284C3.33692 15.4695 3.60037 15.733 3.9415 15.9154C4.30461 16.1096 4.65823 16.1925 5.46 16.2093L5.80569 16.2126H14.1946C15.2479 16.2126 15.6473 16.1355 16.0588 15.9154C16.3999 15.733 16.6634 15.4695 16.8458 15.1284C17.04 14.7653 17.1229 14.4117 17.1397 13.6099L17.143 13.2642V7.73245C17.143 6.80661 17.0834 6.38603 16.9195 6.01834ZM14.1946 4.78404H5.80569C4.87985 4.78404 4.45928 4.84362 4.09158 5.00749L8.98999 9.90659C9.51852 10.4351 10.3581 10.4629 10.9194 9.99004L11.0103 9.90659L15.9087 5.00749C15.541 4.84362 15.1204 4.78404 14.1946 4.78404Z" fill="#CAD4DD" />
                        </svg>
                        <input
                            type="email"
                            value={inputEmail}
                            onChange={(e) => setInputEmail(e.target.value.toLowerCase())}
                            style={{
                                paddingLeft: "46rem",
                                paddingRight: disabled ? "70rem" : ""
                            }} placeholder='Add your email'
                            name='email1'
                        />
                        {disabled && (
                            <div className="change" onClick={handleChange}>
                                Change
                            </div>
                        )}
                        {errorEmail1 && (
                            <div className="error-text">
                                {msgEmail1}
                            </div>
                        )}
                    </div>

                    {showSecondInput && (
                        <div className={`input ${errorEmail2 ? 'error' : ''}`} style={{ marginTop: "10rem" }}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.80569 3.35547L14.412 3.35674C15.5369 3.37038 16.1238 3.49595 16.7325 3.82151C17.3226 4.13709 17.7899 4.60446 18.1055 5.19455C18.4514 5.84132 18.5716 6.46345 18.5716 7.73245V13.2642C18.5716 14.5332 18.4514 15.1553 18.1055 15.8021C17.7899 16.3922 17.3226 16.8596 16.7325 17.1751C16.0857 17.521 15.4636 17.6412 14.1946 17.6412H5.80569C4.53669 17.6412 3.91456 17.521 3.26779 17.1751C2.6777 16.8596 2.21033 16.3922 1.89475 15.8021C1.54885 15.1553 1.42871 14.5332 1.42871 13.2642L1.42998 7.51508C1.44363 6.39017 1.5692 5.80328 1.89475 5.19455C2.21033 4.60446 2.6777 4.13709 3.26779 3.82151C3.91456 3.47561 4.53669 3.35547 5.80569 3.35547ZM16.9195 6.01834L12.0204 10.9167C10.9419 11.9953 9.21545 12.0313 8.09367 11.0246L7.97983 10.9167L3.08073 6.01834C2.91686 6.38603 2.85728 6.80661 2.85728 7.73245V13.2642C2.85728 14.3175 2.9344 14.7169 3.15448 15.1284C3.33692 15.4695 3.60037 15.733 3.9415 15.9154C4.30461 16.1096 4.65823 16.1925 5.46 16.2093L5.80569 16.2126H14.1946C15.2479 16.2126 15.6473 16.1355 16.0588 15.9154C16.3999 15.733 16.6634 15.4695 16.8458 15.1284C17.04 14.7653 17.1229 14.4117 17.1397 13.6099L17.143 13.2642V7.73245C17.143 6.80661 17.0834 6.38603 16.9195 6.01834ZM14.1946 4.78404H5.80569C4.87985 4.78404 4.45928 4.84362 4.09158 5.00749L8.98999 9.90659C9.51852 10.4351 10.3581 10.4629 10.9194 9.99004L11.0103 9.90659L15.9087 5.00749C15.541 4.84362 15.1204 4.78404 14.1946 4.78404Z" fill="#CAD4DD" />
                            </svg>
                            <input type="email"
                                value={inputEmail2}
                                onChange={handleEmailChange}

                                placeholder='Confirm your email'
                                style={{ paddingLeft: "46rem" }}
                                name='email1'
                            />
                            {errorEmail2 && (
                                <div className="error-text">
                                    {msgEmail2}
                                </div>
                            )}
                        </div>
                    )}

                    {showPasswordInput && (
                        <div className={`input ${errorPassword ? 'error' : ''}`} style={{ marginTop: "10rem" }}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0003 1.92773C12.3672 1.92773 14.286 3.84651 14.286 6.21345L14.2875 7.65826C14.8895 7.69557 15.2735 7.80091 15.6695 8.01265C16.1432 8.26598 16.5192 8.642 16.7725 9.11569C17.0477 9.63017 17.1431 10.1246 17.1431 11.1033V15.6093C17.1431 16.588 17.0477 17.0824 16.7725 17.5969C16.5192 18.0706 16.1432 18.4466 15.6695 18.7C15.155 18.9751 14.6605 19.0706 13.6818 19.0706H6.31873C5.34002 19.0706 4.84558 18.9751 4.33109 18.7C3.8574 18.4466 3.48138 18.0706 3.22805 17.5969C2.9529 17.0824 2.85742 16.588 2.85742 15.6093V11.1033C2.85742 10.1246 2.9529 9.63017 3.22805 9.11569C3.48138 8.642 3.8574 8.26598 4.33109 8.01265C4.72717 7.80082 5.11138 7.69548 5.71381 7.65822L5.71456 6.21345C5.71456 3.84651 7.63334 1.92773 10.0003 1.92773ZM13.6818 9.07059H6.31873C5.55568 9.07059 5.28404 9.12305 5.0048 9.27239C4.78007 9.39257 4.60797 9.56467 4.48779 9.7894C4.33845 10.0686 4.28599 10.3403 4.28599 11.1033V15.6093C4.28599 16.3723 4.33845 16.644 4.48779 16.9232C4.60797 17.1479 4.78007 17.32 5.0048 17.4402C5.28404 17.5896 5.55568 17.642 6.31873 17.642H13.6818C14.4449 17.642 14.7165 17.5896 14.9958 17.4402C15.2205 17.32 15.3926 17.1479 15.5128 16.9232C15.6621 16.644 15.7146 16.3723 15.7146 15.6093V11.1033C15.7146 10.3403 15.6621 10.0686 15.5128 9.7894C15.3926 9.56467 15.2205 9.39257 14.9958 9.27239C14.7165 9.12305 14.4449 9.07059 13.6818 9.07059ZM10.0003 11.2134C10.3948 11.2134 10.7146 11.5332 10.7146 11.9277V14.0706C10.7146 14.4651 10.3948 14.7849 10.0003 14.7849C9.60579 14.7849 9.28599 14.4651 9.28599 14.0706V11.9277C9.28599 11.5332 9.60579 11.2134 10.0003 11.2134ZM10.0003 3.35631C8.42232 3.35631 7.14314 4.63549 7.14314 6.21345V7.64202H12.8574V6.21345C12.8574 4.63549 11.5782 3.35631 10.0003 3.35631Z" fill="#CAD4DD" />
                            </svg>
                            <input type={showPassword ? 'text' : 'password'}
                                value={inputPassword}
                                onChange={handlePasswordChange} placeholder={placeholderPassword}
                                style={{ paddingLeft: "46rem", paddingRight: "46rem" }}
                                name='password'
                            />
                            <div className="show" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.05517 4.26529L10.2391 8.44922L10.2417 8.44856L14.0505 12.2573L14.0498 12.2599L18.6266 16.8367C18.9334 17.1436 18.9334 17.641 18.6266 17.9479C18.3198 18.2547 17.8223 18.2547 17.5154 17.9479L16.042 16.4758C14.5201 17.3133 12.7915 17.7852 10.9996 17.7852C6.42811 17.7852 1.96387 14.3626 1.96387 11.4994C1.96387 9.82823 3.68082 7.7521 6.04421 6.47673L4.944 5.37645C4.63716 5.06961 4.63716 4.57213 4.944 4.26529C5.25084 3.95845 5.74832 3.95845 6.05517 4.26529ZM3.5353 11.4994C3.5353 13.3899 7.21857 16.2137 10.9996 16.2137C12.3608 16.2137 13.683 15.8912 14.8763 15.3105L13.2554 13.6878C12.6842 14.2765 11.8846 14.6423 10.9996 14.6423C9.26383 14.6423 7.85672 13.2352 7.85672 11.4994C7.85672 10.6144 8.22254 9.81484 8.8112 9.24366L7.21234 7.64607C5.09127 8.65283 3.5353 10.4261 3.5353 11.4994ZM10.9996 5.21373C15.5711 5.21373 20.0353 8.63631 20.0353 11.4994C20.0353 12.2417 19.6949 13.0695 19.0951 13.8828C18.8375 14.232 18.3456 14.3063 17.9964 14.0488C17.6471 13.7912 17.5728 13.2993 17.8304 12.9501C18.248 12.3839 18.4639 11.8588 18.4639 11.4994C18.4639 9.609 14.7806 6.78516 10.9996 6.78516C10.5656 6.78516 10.2139 6.43338 10.2139 5.99944C10.2139 5.5655 10.5656 5.21373 10.9996 5.21373ZM9.92262 10.3551C9.61821 10.6417 9.42815 11.0484 9.42815 11.4994C9.42815 12.3673 10.1317 13.0709 10.9996 13.0709C11.4507 13.0709 11.8574 12.8808 12.1439 12.5764L9.92262 10.3551Z" fill="black" />
                                    </svg>

                                ) : (
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9996 5.21484C15.7092 5.21484 20.0353 8.79506 20.0353 11.5006C20.0353 14.2061 15.7092 17.7863 10.9996 17.7863C6.28996 17.7863 1.96387 14.2061 1.96387 11.5006C1.96387 8.79506 6.28996 5.21484 10.9996 5.21484ZM10.9996 6.78627C7.09908 6.78627 3.5353 9.73561 3.5353 11.5006C3.5353 13.2655 7.09908 16.2148 10.9996 16.2148C14.9001 16.2148 18.4639 13.2655 18.4639 11.5006C18.4639 9.73561 14.9001 6.78627 10.9996 6.78627ZM10.9996 8.3577C12.7353 8.3577 14.1424 9.76481 14.1424 11.5006C14.1424 13.2363 12.7353 14.6434 10.9996 14.6434C9.26383 14.6434 7.85672 13.2363 7.85672 11.5006C7.85672 9.76481 9.26383 8.3577 10.9996 8.3577ZM10.9996 9.92913C10.1317 9.92913 9.42815 10.6327 9.42815 11.5006C9.42815 12.3684 10.1317 13.072 10.9996 13.072C11.8675 13.072 12.571 12.3684 12.571 11.5006C12.571 10.6327 11.8675 9.92913 10.9996 9.92913Z" fill="#CAD4DD" />
                                    </svg>

                                )}
                            </div>
                            {errorPassword && (
                                <div className="error-text">
                                    {msgPassword}
                                </div>
                            )}
                        </div>
                    )}
                    {showPassword2Input && (
                        <div className={`input ${errorPassword2 ? 'error' : ''}`} style={{ marginTop: "10rem" }}>
                            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.0003 1.92773C12.3672 1.92773 14.286 3.84651 14.286 6.21345L14.2875 7.65826C14.8895 7.69557 15.2735 7.80091 15.6695 8.01265C16.1432 8.26598 16.5192 8.642 16.7725 9.11569C17.0477 9.63017 17.1431 10.1246 17.1431 11.1033V15.6093C17.1431 16.588 17.0477 17.0824 16.7725 17.5969C16.5192 18.0706 16.1432 18.4466 15.6695 18.7C15.155 18.9751 14.6605 19.0706 13.6818 19.0706H6.31873C5.34002 19.0706 4.84558 18.9751 4.33109 18.7C3.8574 18.4466 3.48138 18.0706 3.22805 17.5969C2.9529 17.0824 2.85742 16.588 2.85742 15.6093V11.1033C2.85742 10.1246 2.9529 9.63017 3.22805 9.11569C3.48138 8.642 3.8574 8.26598 4.33109 8.01265C4.72717 7.80082 5.11138 7.69548 5.71381 7.65822L5.71456 6.21345C5.71456 3.84651 7.63334 1.92773 10.0003 1.92773ZM13.6818 9.07059H6.31873C5.55568 9.07059 5.28404 9.12305 5.0048 9.27239C4.78007 9.39257 4.60797 9.56467 4.48779 9.7894C4.33845 10.0686 4.28599 10.3403 4.28599 11.1033V15.6093C4.28599 16.3723 4.33845 16.644 4.48779 16.9232C4.60797 17.1479 4.78007 17.32 5.0048 17.4402C5.28404 17.5896 5.55568 17.642 6.31873 17.642H13.6818C14.4449 17.642 14.7165 17.5896 14.9958 17.4402C15.2205 17.32 15.3926 17.1479 15.5128 16.9232C15.6621 16.644 15.7146 16.3723 15.7146 15.6093V11.1033C15.7146 10.3403 15.6621 10.0686 15.5128 9.7894C15.3926 9.56467 15.2205 9.39257 14.9958 9.27239C14.7165 9.12305 14.4449 9.07059 13.6818 9.07059ZM10.0003 11.2134C10.3948 11.2134 10.7146 11.5332 10.7146 11.9277V14.0706C10.7146 14.4651 10.3948 14.7849 10.0003 14.7849C9.60579 14.7849 9.28599 14.4651 9.28599 14.0706V11.9277C9.28599 11.5332 9.60579 11.2134 10.0003 11.2134ZM10.0003 3.35631C8.42232 3.35631 7.14314 4.63549 7.14314 6.21345V7.64202H12.8574V6.21345C12.8574 4.63549 11.5782 3.35631 10.0003 3.35631Z" fill="#CAD4DD" />
                            </svg>
                            <input type={showPassword ? 'text' : 'password'}
                                value={inputPassword2}
                                onChange={handlePassword2Change} placeholder='Confirm new password'
                                style={{ paddingLeft: "46rem", paddingRight: "46rem" }}
                                name='password'
                            />
                            <div className="show" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? (
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M6.05517 4.26529L10.2391 8.44922L10.2417 8.44856L14.0505 12.2573L14.0498 12.2599L18.6266 16.8367C18.9334 17.1436 18.9334 17.641 18.6266 17.9479C18.3198 18.2547 17.8223 18.2547 17.5154 17.9479L16.042 16.4758C14.5201 17.3133 12.7915 17.7852 10.9996 17.7852C6.42811 17.7852 1.96387 14.3626 1.96387 11.4994C1.96387 9.82823 3.68082 7.7521 6.04421 6.47673L4.944 5.37645C4.63716 5.06961 4.63716 4.57213 4.944 4.26529C5.25084 3.95845 5.74832 3.95845 6.05517 4.26529ZM3.5353 11.4994C3.5353 13.3899 7.21857 16.2137 10.9996 16.2137C12.3608 16.2137 13.683 15.8912 14.8763 15.3105L13.2554 13.6878C12.6842 14.2765 11.8846 14.6423 10.9996 14.6423C9.26383 14.6423 7.85672 13.2352 7.85672 11.4994C7.85672 10.6144 8.22254 9.81484 8.8112 9.24366L7.21234 7.64607C5.09127 8.65283 3.5353 10.4261 3.5353 11.4994ZM10.9996 5.21373C15.5711 5.21373 20.0353 8.63631 20.0353 11.4994C20.0353 12.2417 19.6949 13.0695 19.0951 13.8828C18.8375 14.232 18.3456 14.3063 17.9964 14.0488C17.6471 13.7912 17.5728 13.2993 17.8304 12.9501C18.248 12.3839 18.4639 11.8588 18.4639 11.4994C18.4639 9.609 14.7806 6.78516 10.9996 6.78516C10.5656 6.78516 10.2139 6.43338 10.2139 5.99944C10.2139 5.5655 10.5656 5.21373 10.9996 5.21373ZM9.92262 10.3551C9.61821 10.6417 9.42815 11.0484 9.42815 11.4994C9.42815 12.3673 10.1317 13.0709 10.9996 13.0709C11.4507 13.0709 11.8574 12.8808 12.1439 12.5764L9.92262 10.3551Z" fill="black" />
                                    </svg>

                                ) : (
                                    <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M10.9996 5.21484C15.7092 5.21484 20.0353 8.79506 20.0353 11.5006C20.0353 14.2061 15.7092 17.7863 10.9996 17.7863C6.28996 17.7863 1.96387 14.2061 1.96387 11.5006C1.96387 8.79506 6.28996 5.21484 10.9996 5.21484ZM10.9996 6.78627C7.09908 6.78627 3.5353 9.73561 3.5353 11.5006C3.5353 13.2655 7.09908 16.2148 10.9996 16.2148C14.9001 16.2148 18.4639 13.2655 18.4639 11.5006C18.4639 9.73561 14.9001 6.78627 10.9996 6.78627ZM10.9996 8.3577C12.7353 8.3577 14.1424 9.76481 14.1424 11.5006C14.1424 13.2363 12.7353 14.6434 10.9996 14.6434C9.26383 14.6434 7.85672 13.2363 7.85672 11.5006C7.85672 9.76481 9.26383 8.3577 10.9996 8.3577ZM10.9996 9.92913C10.1317 9.92913 9.42815 10.6327 9.42815 11.5006C9.42815 12.3684 10.1317 13.072 10.9996 13.072C11.8675 13.072 12.571 12.3684 12.571 11.5006C12.571 10.6327 11.8675 9.92913 10.9996 9.92913Z" fill="#CAD4DD" />
                                    </svg>

                                )}
                            </div>
                            {errorPassword2 && (
                                <div className="error-text">
                                    {msgPassword2}
                                </div>
                            )}
                        </div>
                    )}
                </>
            )}




        </div>
    );
}

export default Signup;
