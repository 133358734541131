import React, {useEffect} from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import { gsap }from 'gsap';

const Choose2 = ({setButtonShow, setButtonChoose, setCreativeId,  setCreativeType,  setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setCreativeType('static');
        setCreativeId('e1bc8427-d4a3-4550-9b54-80e3078f5018')
        setButtonShow(false);
        setButtonChoose(true);
        setEventName('web_format_2')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle title="Do you like this creative?" />
            <p style={{ marginBottom: "24rem" }} className="text">*We’ll add this format to your plan</p>
            <div style={{ aspectRatio: "40/51", margin: "0 auto 0rem", maxWidth: '200rem' }}>
                <img src={process.env.PUBLIC_URL + '/img/choose2.jpg'} style={{ borderRadius: '12rem' }} alt="" />
            </div>
            
        </div>
    );
}

export default Choose2;
