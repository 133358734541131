import React, { useState, useEffect } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import mixpanel from 'mixpanel-browser';

const Depends = ({ setButtonShow,setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonShow(false)
        setEventName('web_adsuccess')
    }, []);

    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioClick = (value, nextFunction) => {
        setSelectedRadio(value);
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            
        })
        mixpanel.track('web_adsuccess', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_adsuccess_answer: value,
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
        setTimeout(() => {
            nextFunction();
        }, 350);
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Did you know that 70% of an ad's success depends on the creatives?" />
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'Yes'} onClick={() => handleRadioClick('Yes', next)} name='do' style={{ height: '42rem' }}>
                            Yes
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'I didn’t know'} onClick={() => handleRadioClick('I didn’t know', next)} name='do'  style={{ height: '42rem' }}>
                        I didn’t know
                        </CustomRadio>
                    </>
                )}
            />
        </>
    );
}

export default Depends;