import React, { useState, useEffect } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import { TypeAnimation } from 'react-type-animation';
import CustomTitle from '../components/UI/title/CustomTitle';

const Knowledge2 = ({setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setBlockPaddingBottom, setButtonStatus, setAiShow, setEventProperties, sessionId, setEventName}) => {
    useEffect(() => {
        setButtonText('Continue')
        setSelectedOption(null)
        setButtonShow(true)
        if(setBlockPaddingBottom) {
            setBlockPaddingBottom('32rem')
        }
        if(setButtonStatus) {
            setButtonStatus('')
        }
        if(setAiShow) {
            setAiShow('')
        }
        setEventName('web_whatworks')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);



    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "👎",
            text1: "No worries! AI will keep you updated on the trends in your industry so you use only profitable solutions.",
        },
        {
            id: 'option2',
            checked: false,
            text: "👎",
            text1: "No worries! AI will keep you updated on the trends in your industry so you use only profitable solutions.",
          
        },
        {
            id: 'option3',
            checked: false,
            text: "🤷",
            text1: "No worries! AI will keep you updated on the trends in your industry so you use only profitable solutions.",
           
        },
        {
            id: 'option4',
            checked: false,
            text: "👍",
            text1: "Wow! We can only imagine the conversion rates you'll achieve by adding AI.",
        },
        {
            id: 'option5',
            checked: false,
            text: "👍",
            text1: "Wow! We can only imagine the conversion rates you'll achieve by adding AI.",
        },
    ];


    const handleCheckboxChange = (checkbox) => {
        setSelectedOption(checkbox.id);
        setShowTyping(true);
        if(fisrtText != checkbox.text1) {
            setShowFisrtText(false)
            setFisrtText(checkbox.text1)
            setTimeout(() => {
                setShowFisrtText(true)
            }, 100);
        }
        setError(false);
    };

    const areAllCheckboxesUnchecked = () => {
        return selectedOption === null;
    };

    const [showTyping, setShowTyping] = useState(false);
    const [fisrtText, setFisrtText] = useState('');
    const [showFisrtText, setShowFisrtText] = useState(false);



    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{paddingBottom: "32rem" }}>
            <CustomTitle style={{ marginBottom: "12rem" }} title='"I know what kind of creatives are working for my industry.”' />
            <p className="text" style={{ marginBottom: "24rem" }}>Do you agree with the following statement?</p>
            <div className="emojiRadio">
                <div className="emojiRadio-wrapper">
                {initialCheckboxState.map((checkbox) => (
                    <label className={error ? 'error' : undefined}>
                        <input key={checkbox.id}
                            id={checkbox.id}
                            type="radio"
                            checked={selectedOption === checkbox.id}
                            onChange={() => handleCheckboxChange(checkbox)}
                        />
                        <div className="emojiRadio-item">
                            {checkbox.text}
                        </div>
                    </label>
                ))}
                </div>
                <div className="emojiRadio-bottom">
                    <span>Strongly Disagree</span>
                    <span>Strongly Agree</span>
                </div>
            </div>

            {showTyping && (
                <div className="typing-block" style={{ marginTop: '24rem', minHeight: typeHeight }}>
                    {showFisrtText && (
                        <TypeAnimation
                            sequence={[
                                fisrtText,
                            ]}
                            wrapper="span"
                            speed={80}
                            style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                            repeat={1}
                            cursor={false}
                        />
                    )}
                </div>
            )}



            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    buttonText="Continue"
                    style={{ marginTop: '32rem' }}
                    onClick={() => {
                        if (areAllCheckboxesUnchecked()) {
                            setError(true);setTimeout(() => {
                            setError(false);
                        }, 1000);
                        } else {
                            setError(false);
                            next();
                        }
                    }} className={areAllCheckboxesUnchecked() ? (error ? 'error' : 'disable') : undefined}>
                        Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </div>
    );
}

export default Knowledge2;
