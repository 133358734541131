import React, { useEffect } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomCheckbox from '../components/UI/chekbox/CustomCheckbox';
import CustomTitle from '../components/UI/title/CustomTitle';
import { useHistory } from 'react-router-dom';

const Channels = ({setCheckboxes, checkboxes, setError, error, setButtonText, setButtonShow, setSelectedOption, buttonStatus, setButtonStatus,setEventProperties, sessionId, setEventName}) => {
    const history = useHistory();
    const initialCheckboxState = {
        Facebook: false,
        Instagram: false,
        Pinterest: false,
        Google: false,
        TikTok: false,
        Other: false,
    };

    useEffect(() => {
        setButtonText('Continue')
        setCheckboxes(initialCheckboxState);
        setButtonShow(true);
        setSelectedOption('1');
        if(buttonStatus == 'Summary') {
            setButtonText('Save');
        }
        setEventName('web_channel')

        
        const sellChannels = Object.keys(checkboxes)
            .filter(channel => checkboxes[channel])
            .join(', ');
        
        
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_channel: sellChannels 
        });
        
    }, []);

    useEffect(() => {
        if(buttonStatus == 'SummaryLoad') {
            setButtonStatus("");
            history.push('/summary');
        }
    }, [buttonStatus]);

    useEffect(() => {
        const storedChannelsValue = localStorage.getItem('channels');
        if (storedChannelsValue) {
            const channelsData = JSON.parse(storedChannelsValue);
            setCheckboxes(channelsData);
        }
    }, []);


    const handleCheckboxChange = (checkboxName, isChecked) => {
        setCheckboxes((prevCheckboxes) => {
            const updatedCheckboxes = {
                ...prevCheckboxes,
                [checkboxName]: Boolean(isChecked),
            };
    
            const sellChannels = Object.keys(updatedCheckboxes)
                .filter(channel => updatedCheckboxes[channel])
                .join(', ');

            localStorage.setItem('channels', JSON.stringify(updatedCheckboxes));
    
            var profilesJSON = localStorage.getItem('business-profiles');
            if (profilesJSON) {
                var profiles = JSON.parse(profilesJSON);
                profiles.forEach(function(profile) {
                    profile.sellChannels = sellChannels
                });
                var updatedProfilesJSON = JSON.stringify(profiles);
                localStorage.setItem('business-profiles', updatedProfilesJSON);
            } else {
                var newProfile = {
                    sellChannels: sellChannels
                };
                var newProfiles = [newProfile];
                var newProfilesJSON = JSON.stringify(newProfiles);
                localStorage.setItem('business-profiles', newProfilesJSON);
            }
            setEventProperties({
                session_id: sessionId,
                web_entrance: 'signup',
                web_channel: sellChannels
            })
            
            return updatedCheckboxes;
        });
        setError(false);
    };


    return (
        <div style={{ paddingBottom: "20rem" }}>
            <CustomTitle title="Which channels do you want to sell on?" />
            <p style={{ marginBottom: "12rem" }} className="text">Pick as many as you like</p>
            {Object.keys(checkboxes).map((checkboxName) => (
                <CustomCheckbox
                    key={checkboxName}
                    style={{ height: '45rem' }}
                    checked={checkboxes[checkboxName]}
                    onChange={(isChecked) => handleCheckboxChange(checkboxName, isChecked)}
                    className={error ? 'error' : undefined}
                >
                    <div>
                        <img src={process.env.PUBLIC_URL + `/img/${checkboxName}.svg`} alt="" />
                    </div>
                    {checkboxName}
                </CustomCheckbox>
            ))}
        </div>
    );
}

export default Channels;

