import { React, useState, useEffect } from 'react';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../../components/UI/title/CustomTitle';
import './generate.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

const Generate = ({setButtonShow, setButtonText, setSignupBottom, setEventProperties, sessionId, setEventName}) => {
    const [swiper, setSwiper] = useState(null);
    const [title, setTitle] = useState("We're setting up AI tools and creating a promotion plan for you")
    const [loaderFirstTitle, setLoaderFirstTitle] = useState("Analyzing your business type")
    const [loaderFirstCount, setLoaderFirstCount] = useState(0)
    const [showLoaderFirst, setShowLoaderFirst] = useState(true);

    const [loaderSecondTitle, setLoaderSecondTitle] = useState("Processing your goals")
    const [loaderSecondCount, setLoaderSecondCount] = useState(0)
    const [showLoaderSecond, setShowLoaderSecond] = useState(false);

    const [loaderThirdTitle, setLoaderThirdTitle] = useState("Preparing AI tools")
    const [loaderThirdCount, setLoaderThirdCount] = useState(0)
    const [showLoaderThird, setShowLoaderThird] = useState(false);

    const [loaderFourthTitle, setLoaderFourthTitle] = useState("Generating a growth plan")
    const [loaderFourthCount, setLoaderFourthCount] = useState(0)
    const [showLoaderFourth, setShowLoaderFourth] = useState(false);

    const [loaderDone, setLoaderDone] = useState(false);


    const loaderCountUpdaters = [
        setLoaderFirstCount,
        setLoaderSecondCount,
        setLoaderThirdCount,
        setLoaderFourthCount,
    ];
    useEffect(() => {
        setButtonShow(false)
        setButtonText('Continue')
        setSignupBottom(false)

        setEventName('web_loading')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    useEffect(() => {
        
        const incrementLoader = (index, prevCount) => {
            return index < loaderCountUpdaters.length - 1 && prevCount < 100 ? prevCount + 1 : prevCount;
        };

        const timer = setInterval(() => {
            setLoaderFirstCount((prevCount) => incrementLoader(0, prevCount));
        }, 50);

        const timeout = setTimeout(() => clearInterval(timer), 6000);


        if (loaderFourthCount == 100) {
            setLoaderFourthTitle('Growth plan');
            setLoaderDone(true)
            setTitle('Your plan is ready!')
            setButtonShow(true)
        }

        if (loaderThirdCount == 100) {
            setLoaderThirdTitle('AI tools');
            setShowLoaderFourth(true);
            const timer = setInterval(() => {
                setLoaderFourthCount((prevCount) => incrementLoader(0, prevCount));
            }, 50);
            return () => {
                clearInterval(timer);
                clearTimeout(timeout);
            };
        }

        if (loaderSecondCount == 100) {
            setLoaderSecondTitle('Goals');
            setShowLoaderThird(true);
            const timer = setInterval(() => {
                setLoaderThirdCount((prevCount) => incrementLoader(0, prevCount));
            }, 50);
            return () => {
                clearInterval(timer);
                clearTimeout(timeout);
            };
        }

        if (loaderFirstCount == 100) {
            setLoaderFirstTitle('Business');
            setShowLoaderSecond(true);
          
            const timer = setInterval(() => {
                setLoaderSecondCount((prevCount) => incrementLoader(0, prevCount));
            }, 50);
            return () => {
                clearInterval(timer);
                clearTimeout(timeout);
            };
        }


        return () => {
            clearInterval(timer);
            clearTimeout(timeout);
        };

    }, [loaderCountUpdaters]);

    useEffect(() => {
        if(showLoaderSecond == true){
            swiper.slideNext();
        }
    }, [showLoaderSecond]);

    useEffect(() => {
        if(showLoaderThird == true){
            swiper.slideNext();
        }
    }, [showLoaderThird]);

    useEffect(() => {
        if(showLoaderFourth == true){
            swiper.slideNext();
        }
    }, [showLoaderFourth]);

    return (
        <>
            <CustomTitle style={{ marginBottom: "32rem" }} title={title} />
            <div className="genarate-loader-wrapper">


                <div className="genarate-loader">
                    <div className="genarate-loader-top">
                        <span>{loaderFirstTitle}</span>
                        {showLoaderSecond ? (
                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="18" height="18" rx="9" fill="#2555FF" />
                                <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.8" stroke-linecap="round" />
                            </svg>
                        ) : (
                            <span>{loaderFirstCount}%</span>
                        )}

                    </div>
                    {!showLoaderSecond && (
                        <div className="genarate-loader-line">
                            <strong></strong>
                            <span style={{ width: `${loaderFirstCount}%` }}></span>
                        </div>
                    )}

                </div>

                {showLoaderSecond && (
                    <div className="genarate-loader">
                        <div className="genarate-loader-top">
                            <span>{loaderSecondTitle}</span>

                            {showLoaderThird ? (
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#2555FF" />
                                    <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.8" stroke-linecap="round" />
                                </svg>
                            ) : (
                                <span>{loaderSecondCount}%</span>
                            )}
                        </div>
                        {!showLoaderThird && (
                            <div className="genarate-loader-line">
                                <strong></strong>
                                <span style={{ width: `${loaderSecondCount}%` }}></span>
                            </div>
                        )}

                    </div>
                )}

                {showLoaderThird && (
                    <div className="genarate-loader">
                        <div className="genarate-loader-top">
                            <span>{loaderThirdTitle}</span>
                            {showLoaderFourth ? (
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#2555FF" />
                                    <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.8" stroke-linecap="round" />
                                </svg>
                            ) : (
                                <span>{loaderThirdCount}%</span>
                            )}
                        </div>
                        {!showLoaderFourth && (
                            <div className="genarate-loader-line">
                                <strong></strong>
                                <span style={{ width: `${loaderThirdCount}%` }}></span>
                            </div>
                        )}

                    </div>
                )}

                {showLoaderFourth && (
                    <div className="genarate-loader">
                        <div className="genarate-loader-top">
                            <span>{loaderFourthTitle}</span>
                            {loaderDone ? (
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect width="18" height="18" rx="9" fill="#2555FF" />
                                    <path d="M5.30859 9.00691L7.8727 11.3146L13.0009 6.69922" stroke="white" stroke-width="1.8" stroke-linecap="round" />
                                </svg>
                            ) : (
                                <span>{loaderFourthCount}%</span>
                            )}
                        </div>
                        {!loaderDone && (
                            <div className="genarate-loader-line">
                                <strong></strong>
                                <span style={{ width: `${loaderFourthCount}%` }}></span>
                            </div>
                        )}

                    </div>
                )}
            </div>
            {!loaderDone && (
                <div className="genarate-slider">
                    <Swiper
                        spaceBetween={8}
                        slidesPerView={1.2}
                        centeredSlides={true}
                        // allowSlideNext={false}
                        // allowSlidePrev={false}
                        allowTouchMove={false}
                        onSwiper={(s) => {
                            setSwiper(s);
                        }}
                 
                    >
                        <SwiperSlide>
                            <img src={process.env.PUBLIC_URL + '/img/g1.jpg'} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={process.env.PUBLIC_URL + '/img/g2.jpg'} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={process.env.PUBLIC_URL + '/img/g3.jpg'} alt="" />
                        </SwiperSlide>
                        <SwiperSlide>
                            <img src={process.env.PUBLIC_URL + '/img/g4.jpg'} alt="" />
                        </SwiperSlide>
                    </Swiper>
                </div>

            )}

            {/* {loaderDone && (
                <WithWizard
                    render={({ next, previous, step, steps }) => (
                        <MyButton 
                        buttonText="Continue"
                        onClick={next}>Continue
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                            </svg>
                        </MyButton>
                    )}
                />
            )} */}

        </>
    );
}

export default Generate;
