import { React, useState, useEffect } from 'react';
import MyButton from '../../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../../components/UI/title/CustomTitle';
import Slider from '@mui/material/Slider';
import { TypeAnimation } from 'react-type-animation';
import './orders.scss';
import { useHistory } from 'react-router-dom';

const Orders = ({ setButtonShow, setButtonText, buttonStatus, setButtonStatus, setAiShow, setBlockPaddingBottom, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();



    const sliderValues = [
        "0 - 10", "10 - 30", "30 - 100", "100 - 150", "150 - 300", "300 - 500", "500 - 750", "750 - 1500", "1500 - 3000", "3000 - 5000", "5000 - 10000+"
    ]


    const [selectedRange, setSelectedRange] = useState(sliderValues[1]);

    function businessProfiles(value) {
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.ordersPerMonth = value;
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                ordersPerMonth: value
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
        setEventName('web_orders')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_orders_answer: value
        })
    }


    useEffect(() => {
        setButtonShow(true)
        setButtonText('Continue');
        if (buttonStatus == 'Summary') {
            setButtonText('Save');
            setSelectedRange(sliderValues[localStorage.getItem('orderValue')]);
        } else {
            localStorage.setItem('orderValue', 1);
            localStorage.setItem('order', sliderValues[1]);
            businessProfiles(sliderValues[1])
        }
    }, []);

    // useEffect(() => {
    //     if(buttonStatus == 'SummaryLoad') {
    //         setButtonStatus("");
    //         history.push('/summary');
    //     }else {
    //         setBlockPaddingBottom("32rem")
    //         setButtonStatus("")
    //         setAiShow(false)
    //     }
    // }, [buttonStatus]);
    useEffect(() => {
        if (buttonStatus == 'SummaryLoad') {
            setButtonStatus("");
            history.push('/summary');
        }
    }, [buttonStatus]);


    let storedValue
    let initialValue
    if (buttonStatus == 'Summary') {
        storedValue = localStorage.getItem('orderValue');
        initialValue = storedValue ? Number(storedValue) : 1;
    } else {
        initialValue = 1
    }

    const [defaultValue, setDefaultValue] = useState(initialValue);


    const [showFisrtText, setShowFisrtText] = useState(false);
    const [showSecondText, setShowSecondText] = useState(false);

    const handleChange = (event, newValue) => {
        setSelectedRange(sliderValues[newValue]);
        localStorage.setItem('order', sliderValues[newValue]);
        businessProfiles(sliderValues[newValue])
        localStorage.setItem('orderValue', newValue);
        if (newValue > 2 && newValue < 5) {
            setShowFisrtText(true);
        } else {
            setShowFisrtText(false);
        }

        if (newValue > 4) {
            setShowSecondText(true);
        } else {
            setShowSecondText(false);
        }
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="How many orders do you receive per month?" />
            <p className="text">
                This will help AI build a promotion plan<br />
                for your business
            </p>
            <div className="rangeSlider">
                <div className="rangeSlider-value">
                    {selectedRange}
                </div>
                <div className="rangeSlider-slider">
                    <span className="fisrt">

                    </span>
                    <Slider
                        aria-label="Small steps"
                        defaultValue={defaultValue}
                        onChange={handleChange}
                        step={1}
                        marks
                        min={0}
                        max={sliderValues.length - 1}
                        valueLabelDisplay="none"
                    />
                    <span className="last">

                    </span>
                </div>
                <div className="rangeSlider-typing">
                    {/* {showFisrtText && (
                        <div className="typing-block">

                            <TypeAnimation
                                sequence={[
                                    "That's great! Zeely AI will help you reach your goal fast",
                                ]}
                                wrapper="span"
                                speed={80}
                                style={{ fontSize: '12rem', display: 'block', fontWeight: "400" }}
                                repeat={1}
                                cursor={false}
                            />

                        </div>
                    )}
                    {showSecondText && (
                        <div className="typing-block typing-block--red">

                            <TypeAnimation
                                sequence={[
                                    "Zeely AI might take longer to meet this goal. You can either change the goal or be prepared for a longer wait to reach it",
                                ]}
                                wrapper="span"
                                speed={80}
                                style={{ fontSize: '12rem', display: 'block', fontWeight: "400" }}
                                repeat={1}
                                cursor={false}
                            />

                        </div>
                    )} */}
                </div>


            </div>

            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    buttonText="Continue"
                    onClick={next}>Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </>
    );
}

export default Orders;
