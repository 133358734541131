import React,{useEffect} from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import BeforeAfterComponent from '../components/BeforeAfter/BeforeAfterComponent';

const BeforeAfter3 = ({setButtonShow, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonShow(true);
        setEventName('web_ads')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <div style={{paddingBottom: "32rem" }}>
            <CustomTitle title="Zeely is the #1 tool for 
profitable ads on 
Facebook & Instagram" />
            <p style={{ marginBottom: "24rem" }} className="text">*Based on our users' increased sales results</p>
            <BeforeAfterComponent
                beforeTitle='Ads Before <br>Zeely AI'
                beforeImg="before2.webp"
                beforeText1={(
                    <> 
                    <p style={{marginRight: '6rem'}}>
                    CTR:
                            <span>1.15%</span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7989_58828)">
                                    <path d="M4.64645 9.35355C4.84171 9.54882 5.15829 9.54882 5.35355 9.35355L8.53553 6.17157C8.7308 5.97631 8.7308 5.65973 8.53553 5.46447C8.34027 5.2692 8.02369 5.2692 7.82843 5.46447L5 8.29289L2.17157 5.46447C1.97631 5.2692 1.65973 5.2692 1.46447 5.46447C1.2692 5.65973 1.2692 5.97631 1.46447 6.17157L4.64645 9.35355ZM4.5 1L4.5 9L5.5 9L5.5 1L4.5 1Z" fill="#EF2C4F" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7989_58828">
                                        <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </p>
                        <p>
                        ROAS:
                            <span>100%</span>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_7989_58828)">
                                    <path d="M4.64645 9.35355C4.84171 9.54882 5.15829 9.54882 5.35355 9.35355L8.53553 6.17157C8.7308 5.97631 8.7308 5.65973 8.53553 5.46447C8.34027 5.2692 8.02369 5.2692 7.82843 5.46447L5 8.29289L2.17157 5.46447C1.97631 5.2692 1.65973 5.2692 1.46447 5.46447C1.2692 5.65973 1.2692 5.97631 1.46447 6.17157L4.64645 9.35355ZM4.5 1L4.5 9L5.5 9L5.5 1L4.5 1Z" fill="#EF2C4F" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_7989_58828">
                                        <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </p>
                    </>
                )}
                beforeText2="1.90%"
                afterTitle="Ads After <br>Zeely AI"
                afterImg="after2.webp"
                afterText1={(
                    <>
                    <p style={{marginRight: '6rem'}}>
                    CTR:
    
                        <span >9.45%</span>
                        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_58851)">
                                <path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_58851">
                                    <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg>
                    </p>
                    <p >
                    ROAS:
                    
                        <span>3,100%</span>
                        <svg   width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clip-path="url(#clip0_7989_58851)">
                                <path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C" />
                            </g>
                            <defs>
                                <clipPath id="clip0_7989_58851">
                                    <rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)" />
                                </clipPath>
                            </defs>
                        </svg> 
                    </p>
                    </>
                )}
                
            ></BeforeAfterComponent>
            {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    style={{ marginTop: "32rem" }}
                    buttonText="Continue"
                    onClick={next}>Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </div>
    );
}

export default BeforeAfter3;
