import React, { useState, useEffect } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
const Do = ({ setButtonShow, setButtonTime, buttonStatus, setButtonStatus, sessionId, sethomeShow, setBlockPaddingBottom }) => {

    const history = useHistory();

    // setButtonOpacity(true);
    // useEffect(() => {
    //     setTimeout(() => {
    //         setButtonShow(false)
    //     }, 635);
    // }, []);
    useEffect(() => {
        setButtonShow(false)
        setButtonTime(0);
        sethomeShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        document.documentElement.style.fontSize = ''; 

    }, []);

    const [radio1Checked, setRadio1Checked] = useState(false);
    const [radio2Checked, setRadio2Checked] = useState(false);
    const handleRadioClick = (option, nextFunction, value) => {
        localStorage.setItem('do', option);
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function(profile) {
                profile.businessStage = value;
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                businessStage: value
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        if (option === 'do1') {
            setRadio1Checked(true);
            setRadio2Checked(false);
            localStorage.setItem('doValue', "Just starting");
            mixpanel.track('web_sellingstage', {
                session_id: sessionId,
                web_entrance: 'signup',
                web_selllingstage: "Just starting",
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
funnel: 'MAIN_v1',
            });
        } else if (option === 'do2') {
            setRadio1Checked(false);
            setRadio2Checked(true);
            localStorage.setItem('doValue', "Already selling");
            mixpanel.track('web_sellingstage', {
                session_id: sessionId,
                web_entrance: 'signup',
                web_selllingstage: "Already selling",
                web_utm_campaign: localStorage.getItem("utmCampaign"),
                web_utm_source: localStorage.getItem("utmSource"),
                web_utm_medium: localStorage.getItem("utmMedium"),
                web_utm_content: localStorage.getItem("utmContent"),
                web_utm_term: localStorage.getItem("utmTerm"),
                isDebug: false,
funnel: 'MAIN_v1',
            });
        }
        if (buttonStatus == 'Summary') {
            setButtonStatus("");
            setTimeout(() => {
                history.push('/summary');
            }, 350);

        } else {
            setTimeout(() => {
                nextFunction();
            }, 350);
        }

    };

    return (
        <>
            <CustomTitle title="Which of these best describes you?" />
            <p style={{ marginBottom: "24rem" }} className="text">This will help our AI create a plan for you based on the stage of your business</p>

            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={radio1Checked} onClick={() => handleRadioClick('do1', next, 'Just starting')} name='do' style={{ marginBottom: '20rem' }}>
                            <img src={process.env.PUBLIC_URL + '/img/do1.png'} alt="" />
                            Just starting
                        </CustomRadio>
                        <CustomRadio checked={radio2Checked} onClick={() => handleRadioClick('do2', next, 'Already selling')} name='do'>
                            <img src={process.env.PUBLIC_URL + '/img/do2.png'} alt="" />
                            Already selling
                        </CustomRadio>
                    </>
                )}
            />
        </>
    );
}

export default Do;
