import React, { useState, useEffect } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import mixpanel from 'mixpanel-browser';

const Launch = ({ setButtonShow, setButtonTime, setSelectedOption,setAiShow, setButtonStatus,setBlockPaddingBottom,setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonShow(false)
        setButtonTime(0);
        setSelectedOption('1');
        setAiShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')

        setEventName('web_haveyouever_1')
    }, []); 
   
    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioClick = (value, nextFunction) => {
        setSelectedRadio(value);
        var profilesJSON = localStorage.getItem('business-profiles');
        var result;

        mixpanel.track('web_haveyouever_1', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_haveyouever_1_answer: value,
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
        if(value == 'yes'){
            result = true;
            
        }
        if(value == 'no') {
            result = false;
        }
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function(profile) {
                profile.launchAdsBefore = result
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                launchAdsBefore: result
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
        setTimeout(() => {
            nextFunction();
        }, 350);
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Have you ever launched online ads?" />
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'yes'}  onClick={() => handleRadioClick('yes', next,)} name='do'  style={{ height: '42rem' }}>
                            Yes
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'no'}  onClick={() => handleRadioClick('no', next)} name='do'  style={{ height: '42rem' }}>
                        Not yet
                        </CustomRadio>
                    </>
                )}
            />
        </>
    );
}

export default Launch;
