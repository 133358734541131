import React, { useEffect, useState } from 'react';
import LogoDark from '../components/Logo/LogoDark';
import CustomTitle from '../components/UI/title/CustomTitle';
import CustomRadio from '../components/UI/radio/CustomRadio';

const Platform = ({ setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setButtonTime, setAiShow, setButtonStatus,setBlockPaddingBottom,setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonText('Continue')
        setButtonShow(true);
        setButtonTime(150);
        setSelectedOption(null)
        setAiShow(false)
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setEventName('web_platform')
    }, []);

    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "Shopify",
            img: 'Shopify.svg',
        },
        {
            id: 'option2',
            checked: false,
            text: "WordPress",
            img: 'WordPress.svg',
        },
        {
            id: 'option3',
            checked: false,
            text: "Wix",
            img: 'Wix.svg',
        },
        {
            id: 'option4',
            checked: false,
            text: "Drupal",
            img: 'Drupal.svg',
        },
        {
            id: 'option5',
            checked: false,
            text: "Joomla",
            img: 'Joomla.svg',
        },
        {
            id: 'option6',
            checked: false,
            text: "Something else",
            img: 'Other.svg',
        },
    ];


    const [other, setOther] = useState('');

    const handleCheckboxChange = (checkbox) => {


        setSelectedOption(checkbox.id);
        if(checkbox.id == 'option6') {
            setShowTyping(true);
            businessProfiles(other)
            localStorage.setItem('structure', "OTHER");

        } else {
            businessProfiles(checkbox.text)
            setShowTyping(false);
            localStorage.setItem('structure', checkbox.text);
        }
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_platform_name: checkbox.text,
            web_site_type: checkbox.text,
        })
        
       

        setError(false);
    };

    const [showTyping, setShowTyping] = useState(false);



    function businessProfiles(value) {
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function(profile) {
                profile.sitePlatform = value
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                sitePlatform: value
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }
    }


    const handleInputChange = (event) => {
        setOther(event.target.value);
        businessProfiles(event.target.value)
      };
    return (
        <div style={{ paddingBottom: "12rem" }}>
            {/* <LogoDark style={{ marginBottom: '32rem', }} /> */}
            <CustomTitle style={{ marginBottom: "24rem" }} title="Choose which platform  you have your website on" />
            {initialCheckboxState.map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ marginBottom: '20rem' }}
                >
                    <img src={process.env.PUBLIC_URL + `/img/${checkbox.img}`} style={{ minWidth: '20rem', width: "20rem", height: "20rem", borderRadius: '0' }} alt="" />
                    {checkbox.text}
                </CustomRadio>
            ))}
            {showTyping && (
                <div className="input" style={{ marginBottom: '20rem' }}>
                    <input
                        type="text"     onChange={handleInputChange} value={other} placeholder='Enter name of your platform' />
                </div>
            )}
        </div>
    );
}

export default Platform;
