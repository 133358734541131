import React, { useState, useEffect }  from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import CustomTitle from '../components/UI/title/CustomTitle';
import { useHistory } from 'react-router-dom';
import mixpanel from 'mixpanel-browser';
const Reach = ({setButtonShow, buttonStatus, setButtonStatus, setButtonTime, setEventProperties, sessionId, setEventName}) => {
    const history = useHistory();
    useEffect(() => {
        setButtonShow(false);
        setButtonTime(0);
    }, []);
    const [selectedRadio, setSelectedRadio] = useState(null);

    const handleRadioClick = (value, text, nextFunction) => {
        setSelectedRadio(value);
        localStorage.setItem('predict', value);
        localStorage.setItem('reach', text);
        mixpanel.track('web_timeline', {
            session_id: sessionId,
            web_entrance: 'signup',
            web_timeline_answer: text,
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        });
        var profilesJSON = localStorage.getItem('business-profiles');
        if (profilesJSON) {
            var profiles = JSON.parse(profilesJSON);
            profiles.forEach(function (profile) {
                profile.ordersGoalPeriod = text; 
            });
            var updatedProfilesJSON = JSON.stringify(profiles);
            localStorage.setItem('business-profiles', updatedProfilesJSON);
        } else {
            var newProfile = {
                ordersGoalPeriod: text
            };
            var newProfiles = [newProfile];
            var newProfilesJSON = JSON.stringify(newProfiles);
            localStorage.setItem('business-profiles', newProfilesJSON);
        }

        if (buttonStatus == 'Summary') {
            setButtonStatus("");
            setTimeout(() => {
                history.push('/summary');
            }, 350);

        } else {
            setTimeout(() => {
                nextFunction();
            }, 350);
        }
    };
    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="When do you want to reach your goal?" />
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <>
                        <CustomRadio checked={selectedRadio === 'option1'} onClick={() => handleRadioClick('option1', "In 2-3 months", next)} name='do' style={{ height: '42rem' }}>
                            In 2-3 months
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'option2'} onClick={() => handleRadioClick('option2', "In 3-5 months", next)} name='do' style={{ height: '42rem' }}>
                            In 3-5 months
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'option3'} onClick={() => handleRadioClick('option3', "In 5-9 months",next)} name='do' style={{ height: '42rem' }}>
                            In 5-9 months
                        </CustomRadio>
                        <CustomRadio checked={selectedRadio === 'option4'} onClick={() => handleRadioClick('option4', "In a year", next)} name='do' style={{ height: '42rem' }}>
                        In a year
                        </CustomRadio>
                    </>
                )}
            />
        </>
    );
}

export default Reach;
