import React, { useEffect } from 'react';
import CustomTitle from '../components/UI/title/CustomTitle';

const Revenue2 = ({ setButtonShow, setButtonText, setButtonChoose,setSelectedOption, setButtonTime, setBlockPaddingBottom,setButtonStatus, setAiShow, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonText('Continue')
        setButtonShow(true);
        setButtonChoose(false);
        setSelectedOption('1')
        setButtonTime(150)
        setBlockPaddingBottom('32rem')
        setButtonStatus('')
        setAiShow(false)
        setEventName('web_includeads')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <>
            <CustomTitle title="Want to boost your sales faster? Just include AI ads in your plan" />

            <p style={{ marginBottom: "24rem" }} className="text">*The data is based on the results of our users</p>
            <div style={{ aspectRatio: "303/167" }}>
                <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + '/img/revenue2.svg'} alt="" />
            </div>

            <div style={{ paddingBottom: "32rem" }}></div>
        </>
    );
}

export default Revenue2;
