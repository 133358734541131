import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import CustomTitle from '../components/UI/title/CustomTitle';
import { Autoplay, FreeMode } from 'swiper/modules';

const Videos = ({ setButtonShow, setButtonText, setCheckboxes, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setButtonText('Сontinue')
        setButtonShow(true);
        setCheckboxes({
            "Generating ideas": true,
        })
        setEventName('web_videos')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);


    const videoRefs = [useRef(null), useRef(null), useRef(null), useRef(null), useRef(null), useRef(null)];
    const [loadings, setLoadings] = useState([true, true, true, true, true, true]);

    const handleLoadedData = (index) => {
        setLoadings(prevLoadings => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = false;
            return newLoadings;
        });
    };
    return (
        <div style={{ paddingBottom: '32rem' }}>
            <CustomTitle style={{ marginBottom: '24rem' }} title="Zeely is the #1 tool for generating AI video creatives" />

            <div style={{ width: "calc(100% + 40rem)", margin: '0 -20rem', }}>
                <div className="personal videos">
                    <Swiper
                        spaceBetween={11}
                        slidesPerView={2.8}
                        // centeredSlides={true}
                        // allowSlideNext={false}
                        // allowSlidePrev={false}
                        allowTouchMove={false}

                        speed={2000}
                        loop={true}
                        autoplay={{
                            delay: 0,
                            disableOnInteraction: false,

                        }}
                        freeMode={true}
                        modules={[Autoplay, FreeMode]}
                    // onSwiper={(s) => {
                    //     setSwiper(s);
                    // }}

                    >
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[0] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi7.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[0]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(0)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v7.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+97%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[1] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi1.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[1]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(1)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v1.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+270%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[2] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi2.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[2]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(2)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v2.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+150%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[3] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi3.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[3]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(3)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v3.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+83%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[4] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi4.webp`} alt="" />
                                    </div>
                                )}

                                <video
                                    ref={videoRefs[4]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(4)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v4.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+410%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[5] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi5.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[5]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(5)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v5.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+210%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="before-item__img" style={{ aspectRatio: "65/116", marginBottom: 8 }}>
                                {loadings[6] && (
                                    <div style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        overflow: 'hidden',
                                        borderRadius: '16rem'
                                    }}>
                                        {/* Skeleton loader */}
                                        <img style={{ width: '100%' }} src={process.env.PUBLIC_URL + `/img/vi6.webp`} alt="" />
                                    </div>
                                )}
                                <video
                                    ref={videoRefs[6]}
                                    style={{ width: '100%', borderRadius: '12rem' }}
                                    autoPlay
                                    loop
                                    muted
                                    playsInline
                                    onLoadedData={() => handleLoadedData(6)}
                                >
                                    <source src={process.env.PUBLIC_URL + `/video/v6.mp4`} type="video/mp4" />
                                </video>
                            </div>
                            <div className="before-item-bottom" >
                                <p style={{ margin: "0 auto" }}>Sales Growth:<span>+190%</span>
                                    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_7989_58851)"><path d="M5.35355 0.646446C5.15829 0.451184 4.84171 0.451184 4.64645 0.646446L1.46447 3.82843C1.2692 4.02369 1.2692 4.34027 1.46447 4.53553C1.65973 4.7308 1.97631 4.7308 2.17157 4.53553L5 1.70711L7.82843 4.53553C8.02369 4.7308 8.34027 4.7308 8.53553 4.53553C8.7308 4.34027 8.7308 4.02369 8.53553 3.82843L5.35355 0.646446ZM5.5 9L5.5 1L4.5 1L4.5 9L5.5 9Z" fill="#4FDB4C"></path></g><defs><clipPath id="clip0_7989_58851"><rect width="9" height="10" fill="white" transform="translate(9.5 10) rotate(-180)"></rect></clipPath></defs></svg>
                                </p>
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>
            </div>

        </div>
    );
}

export default Videos;