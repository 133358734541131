import React from 'react';
import './beforeafter.scss';

const BeforeAfterComponent = ({beforeTitle, beforeImg, beforeText1,beforeText2, afterTitle, afterImg, afterText1,afterText2}) => {
    return (
        <div className="before">
            <div className="before-item">
                <div className="before-item-info">
                    <span dangerouslySetInnerHTML={{ __html: beforeTitle}}></span>
             
                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 6L12.5622 0H0.437822L6.5 6Z" fill="#EF2C4F" />
                    </svg>

                </div>
                <div className="before-item__img" style={{ aspectRatio: "1/1" }}>
                    <img src={process.env.PUBLIC_URL + `/img/${beforeImg}`} alt="" />
                    
                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12.5" cy="12" r="12" fill="#EF2C4F" />
                        <path d="M8.12049 7.59706C8.34365 7.3739 8.70546 7.3739 8.92861 7.59706L12.5246 11.1925L16.1205 7.59706C16.3265 7.39106 16.6506 7.37522 16.8748 7.54952L16.9286 7.59706C17.1518 7.82021 17.1518 8.18202 16.9286 8.40518L13.3331 12.0011L16.9286 15.5971C17.1346 15.803 17.1505 16.1272 16.9762 16.3513L16.9286 16.4052C16.7055 16.6283 16.3436 16.6283 16.1205 16.4052L12.5246 12.8097L8.92861 16.4052C8.72262 16.6112 8.39849 16.627 8.17433 16.4527L8.12049 16.4052C7.89734 16.182 7.89734 15.8202 8.12049 15.5971L11.716 12.0011L8.12049 8.40518C7.9145 8.19919 7.89866 7.87505 8.07296 7.65089L8.12049 7.59706Z" fill="white" />
                    </svg>
                </div>
                <div className="before-item-bottom" >
                    
                    {beforeText1}
                </div>

            </div>
            <div className="before-item">
                <div className="before-item-info before-item-info--green">
                    <span dangerouslySetInnerHTML={{ __html: afterTitle}}></span>
                    <svg width="13" height="6" viewBox="0 0 13 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.5 6L12.5622 0H0.437822L6.5 6Z" fill="#5BF0A5" />
                    </svg>

                </div>
                <div className="before-item__img" style={{ aspectRatio: "1/1" }}>
                    <img src={process.env.PUBLIC_URL + `/img/${afterImg}`} alt="" />
                    

                    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_7989_58843)">
                            <circle cx="12.5" cy="12" r="12" fill="#5BF0A5" />
                            <path d="M10.7856 14.6204L7.76113 11.5959C7.53798 11.3728 7.17617 11.3728 6.95301 11.5959C6.72986 11.8191 6.72986 12.1809 6.95301 12.4041L10.3816 15.8326C10.6047 16.0558 10.9665 16.0558 11.1897 15.8326L18.0468 8.97549C18.27 8.75233 18.27 8.39052 18.0468 8.16737C17.8237 7.94421 17.4619 7.94421 17.2387 8.16737L10.7856 14.6204Z" fill="black" />
                        </g>
                        <defs>
                            <clipPath id="clip0_7989_58843">
                                <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <div className="before-item-bottom">
                {afterText1}
                    
                    
                </div>

            </div>
        </div>
    );
}

export default BeforeAfterComponent;
