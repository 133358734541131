import React, {useEffect} from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import { gsap }from 'gsap';

const Choose3 = ({setButtonShow, setButtonChoose, setButtonStatus, setBlockPaddingBottom, setAiShow,  setCreativeId,  setCreativeType,  setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setCreativeType('static');
        setCreativeId('f24887e2-f033-4604-ad2e-3c5c87ba615e')
        setButtonShow(false);
        setButtonChoose(true);
        setButtonStatus('')
        setBlockPaddingBottom('32rem')
        setAiShow(false)
        setEventName('web_format_3')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);
    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle title="Do you like this creative?" />
            <p style={{ marginBottom: "24rem" }} className="text">*We’ll add this format to your plan</p>
            <div style={{ aspectRatio: "40/51", margin: "0 auto 0rem", maxWidth: '200rem' }}>
                <img src={process.env.PUBLIC_URL + '/img/choose3.jpg'} style={{ borderRadius: '12rem' }} alt="" />
            </div>
            
            </div>
    );
}

export default Choose3;
