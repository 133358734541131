import React, { useEffect, useState } from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../../components/UI/title/CustomTitle';
import MyButton from '../../components/UI/button/MyButton';
import './summary.scss';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const Summary = ({ setButtonShow, setButtonText, setButtonStatus, setEventProperties, sessionId, setEventName }) => {
    const history = useHistory();
    useEffect(() => {
        setEventName('web_summary')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
        setButtonShow(true)
        setButtonText('Show my personal plan');
        const setVHVariable = () => {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        };
        setVHVariable();
        window.addEventListener('resize', setVHVariable);
        return () => window.removeEventListener('resize', setVHVariable);
    }, []);

    const [sell, setSell] = useState('');
    const [country, setCountry] = useState('');
    const [doValue, setDoValue] = useState('');
    const [income, setIncome] = useState('');
    const [income2, setIncome2] = useState('');
    const [reach, setReach] = useState('');
    const [channels, setChannels] = useState([]);


    useEffect(() => {
        const storedSellValue = localStorage.getItem('sell');
        if (storedSellValue) {
            setSell(storedSellValue);
        }

        const storedCountryValue = localStorage.getItem('country');
        if (storedCountryValue) {
            setCountry(storedCountryValue);
        }

        const storedDoValue = localStorage.getItem('doValue');
        if (storedDoValue) {
            setDoValue(storedDoValue);
        }


        const storedIncomeValue = localStorage.getItem('order');
        if (storedIncomeValue) {
            setIncome(`${storedIncomeValue} orders per month`);
        }

        const storedIncome2Value = localStorage.getItem('order2');
        if (storedIncome2Value) {
            setIncome2(`${storedIncome2Value} orders`);
        }

        const storedreachValue = localStorage.getItem('reach');
        if (storedreachValue) {
            setReach(storedreachValue);
        }

        const storedChannelsValue = localStorage.getItem('channels');
        if (storedChannelsValue) {
            const channelsData = JSON.parse(storedChannelsValue);
            setChannels(channelsData);
        }
    }, []);

    const handleClick = (link) => {
        setButtonStatus("Summary");
        history.push(link);
    }
    return (
        <div className="summary-container">
            <CustomTitle style={{ marginBottom: "28rem" }} title="Great job! Here is your summary:" />

            <div className="summary">
                <div className="summary-block">
                    <div className="summary-block__title">
                        Brand
                    </div>
                    {/* <div className="summary-block-line">
                        <span>Name:</span>
                        <div className="summary-block-line-items">
                            <div className="summary-block-line-item">Rollers</div>
                        </div>
                    </div> */}
                    {sell && (
                        <div className="summary-block-line">
                            <span>Category:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/products')}>{sell}</div>
                            </div>
                        </div>
                    )}
                    {country && (
                        <div className="summary-block-line">
                            <span>Location:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/location')}>{country}</div>
                            </div>
                        </div>
                    )}
                    {doValue && (
                        <div className="summary-block-line">
                            <span>Business stage:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/stage')}>{doValue}</div>
                            </div>
                        </div>
                    )}
                    {income && (
                        <div className="summary-block-line">
                            <span>Orders:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/orders')}>{income}</div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="summary-block">
                    <div className="summary-block__title">
                        Goals
                    </div>
                    {income2 && (
                        <div className="summary-block-line">
                            <span>Goal:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/ordersgoal')}>{income2}</div>
                            </div>
                        </div>
                    )}
                    {reach && (
                        <div className="summary-block-line">
                            <span>When:</span>
                            <div className="summary-block-line-items">
                                <div className="summary-block-line-item" onClick={() => handleClick('/timeline')}>{reach}</div>
                            </div>
                        </div>
                    )}

                </div>
                <div className="summary-block">
                    <div className="summary-block__title">
                        Channels
                    </div>
                    {channels && Object.keys(channels).length > 0 && (
                        <div className="summary-block-line">
                            <span>Platforms:</span>
                            <div className="summary-block-line-items">
                                {Object.keys(channels).filter(checkboxName => channels[checkboxName]).map(checkboxName => (
                                    <div className="summary-block-line-item" onClick={() => handleClick('/channels')}>{checkboxName}</div>
                                ))}
                            </div>
                        </div>
                    )}

                </div>
                <div className="summary-block">
                    <div className="summary-block__title">
                        Tools
                    </div>
                    <div className="summary-block-line" style={{ marginBottom: '12rem' }}>
                        <span>Promotion:</span>
                        <div className="summary-block-line-items">
                            <div className="summary-block-line-item" >Ad creatives</div>
                            <div className="summary-block-line-item" >Online ads</div>
                            <div className="summary-block-line-item" >Artificial intelligence</div>
                        </div>

                    </div>
                    <div className="summary-block-line">
                        <span>Sales:</span>
                        <div className="summary-block-line-items">
                            <div className="summary-block-line-item" >Stripe</div>
                            <div className="summary-block-line-item" >PayPal</div>
                            <div className="summary-block-line-item" >Order-tracking system</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="summary-bottom">
                {/* <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    buttonText="Show my personal plan"
                    onClick={next}>Show my personal plan
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
            </div>

        </div>
    );
}

export default Summary;
