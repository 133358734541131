import React, {useEffect} from 'react';
import { WithWizard } from 'react-albus-react18';
import CustomTitle from '../components/UI/title/CustomTitle';
import { gsap }from 'gsap';

const Choose1 = ({setButtonShow, setButtonChoose, setButtonTime, setSelectedOption, setCreativeId,  setCreativeType, setEventProperties, sessionId, setEventName }) => {
    useEffect(() => {
        setCreativeType('static');
        setCreativeId('afc088bc-43ea-4c78-92c4-ba0d79aeae24')
        setButtonShow(false);
        setButtonChoose(true);
        setButtonTime(0);
        setSelectedOption(null)
        setEventName('web_format_1')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);

    return (
        <div style={{ paddingBottom: "32rem" }}>
            <CustomTitle  title="Do you like this creative?" />
            <p style={{ marginBottom: "24rem" }} className="text">*We’ll add this format to your plan</p>
            <div style={{ aspectRatio: "40/51", margin: "0 auto 0rem", maxWidth: '200rem' }}>
                <img src={process.env.PUBLIC_URL + '/img/choose1.jpg'} style={{ borderRadius: '12rem' }} alt="" />
            </div>
            {/* <WithWizard
                render={({ next, previous, step, steps }) => (

                    <div className='choose-action'>
                        <div className="choose-action-btn" onClick={(event) => handleClick(next, event.currentTarget)}>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.24485 4.49493C5.5238 4.21598 5.97606 4.21598 6.25501 4.49493L10.7499 8.98929L15.2449 4.49493C15.5023 4.23744 15.9075 4.21763 16.1877 4.43551L16.255 4.49493C16.534 4.77388 16.534 5.22614 16.255 5.50508L11.7606 10L16.255 14.4949C16.5125 14.7524 16.5323 15.1576 16.3144 15.4378L16.255 15.5051C15.9761 15.784 15.5238 15.784 15.2449 15.5051L10.7499 11.0107L6.25501 15.5051C5.99752 15.7626 5.59235 15.7824 5.31215 15.5645L5.24485 15.5051C4.96591 15.2261 4.96591 14.7739 5.24485 14.4949L9.73922 10L5.24485 5.50508C4.98737 5.24759 4.96756 4.84243 5.18543 4.56222L5.24485 4.49493Z" fill="#EF2C4F" stroke="#EF2C4F" stroke-width="0.4" />
                            </svg>
                            No
                        </div>
                        <div className="choose-action-btn" onClick={(event) => handleClick(next, event.currentTarget)}>
                            <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.60693 13.2756L4.8263 9.49492C4.54735 9.21598 4.09509 9.21598 3.81614 9.49492C3.5372 9.77387 3.5372 10.2261 3.81614 10.5051L8.10186 14.7908C8.3808 15.0697 8.83306 15.0697 9.11201 14.7908L17.6834 6.21936C17.9624 5.94042 17.9624 5.48816 17.6834 5.20921C17.4045 4.93026 16.9522 4.93026 16.6733 5.20921L8.60693 13.2756Z" fill="#5BF0A5" stroke="#5BF0A5" stroke-width="0.444444" />
                            </svg>
                            Yes
                        </div>
                    </div>
                )}
            /> */}
        </div>
    );
}

export default Choose1;
