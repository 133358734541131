import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import './customCheckbox.scss';

const CustomCheckbox = ({ children, style, className, checked, onChange }) => {
    const combinedClassName = `checkboxIcon ${className || ''}`;
    const checkboxRef = useRef(null);
    const checkboxIconRef = useRef(null);
    const checkboxTextRef = useRef(null);
    useEffect(() => {
        const tl = gsap.timeline();
        const tl2 = gsap.timeline();
        const tl3 = gsap.timeline();
        if (checked == true) {
            tl2.to(checkboxIconRef.current, {
                opacity: 1,
                duration: 0.15,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 0.77,
                background: 'transparent',
                opacity: 0.32,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 1,
                background: '#000',
                borderColor: "#000",
                opacity: 1,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 0.32,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 1,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
        } else {
            tl2.to(checkboxIconRef.current, {
                opacity: 0,
                duration: 0.15,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 0.77,
                background: 'transparent',
                borderColor: "#CAD4DD",
                duration: 0.15,
                opacity: 0.32,
                ease: "back.inOut(1.4)",
            });
            tl.to(checkboxRef.current, {
                scale: 1,
                background: 'transparent',
                duration: 0.15,
                opacity: 1,
                ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 0.32,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
            tl3.to(checkboxTextRef.current, {
                opacity: 1,
                duration: 0.15, ease: "back.inOut(1.4)",
            });
        }
        return () => {
            tl.kill();
        };
    }, [checked]);
    return (
        <>
            <label className="checkbox" style={style}>
                <input type="checkbox" checked={checked} onChange={(e) => onChange(e.target.checked)} />
                <span ref={checkboxTextRef} >{children}</span>
                <div className={combinedClassName} ref={checkboxRef}>
                    <svg ref={checkboxIconRef} xmlns="http://www.w3.org/2000/svg" width="10" height="8" viewBox="0 0 10 8" fill="none">
                        <path d="M1.30859 4.0077L3.8727 6.3154L9.0009 1.70001" stroke="white" strokeWidth="1.6" strokeLinecap="round" />
                    </svg>
                </div>
            </label>
        </>
    );
}

export default CustomCheckbox;
