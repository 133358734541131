import React, { useState, useEffect } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import { TypeAnimation } from 'react-type-animation';
import CustomTitle from '../components/UI/title/CustomTitle';

const Statement = ({setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setAiShow, setBlockPaddingBottom, setButtonStatus, setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonText('Continue')
        setSelectedOption(null)
        setButtonShow(true)
        setAiShow(false)
        setBlockPaddingBottom("32rem")
        setButtonStatus('')
        setEventName('web_overspending')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);


    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "👎",
            text1: "Don't worry! We understand your needs and will choose the right tools to help your brand flourish.",
        },
        {
            id: 'option2',
            checked: false,
            text: "👎",
            text1: "Don't worry! We understand your needs and will choose the right tools to help your brand flourish.",
          
        },
        {
            id: 'option3',
            checked: false,
            text: "🤷",
            text1: "Don't worry! We understand your needs and will choose the right tools to help your brand flourish.",
           
        },
        {
            id: 'option4',
            checked: false,
            text: "👍",
            text1: "You're in the right place! No more paying for marketing experts. We have the tools you need.",
        },
        {
            id: 'option5',
            checked: false,
            text: "👍",
            text1: "You're in the right place! No more paying for marketing experts. We have the tools you need.",
        },
    ];

    const handleCheckboxChange = (checkbox) => {
        
        setSelectedOption(checkbox.id);
        setShowTyping(true);



        if(fisrtText != checkbox.text1) {
            setShowFisrtText(false)
            setFisrtText(checkbox.text1)
            setTimeout(() => {
                setShowFisrtText(true)
            }, 100);
        }

   
        setError(false);
    };

    const [showTyping, setShowTyping] = useState(false);
    const [fisrtText, setFisrtText] = useState('');
    const [showFisrtText, setShowFisrtText] = useState(false);


    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div style={{paddingBottom: '24rem' }}>
            <CustomTitle title="“I'm looking to avoid overspending on marketers&nbsp;and&nbsp;designers.”" />
            <p className="text" style={{ marginBottom: '24rem' }}>
            Do you agree with the following statement?
            </p>
            <div className="emojiRadio">
                <div className="emojiRadio-wrapper">
                {initialCheckboxState.map((checkbox) => (
                    <label className={error ? 'error' : undefined}>
                        <input key={checkbox.id}
                            id={checkbox.id}
                            type="radio"
                            checked={selectedOption === checkbox.id}
                            onChange={() => handleCheckboxChange(checkbox)}
                        />
                        <div className="emojiRadio-item">
                            {checkbox.text}
                        </div>
                    </label>
                ))}
                </div>
                <div className="emojiRadio-bottom">
                    <span>Strongly Disagree</span>
                    <span>Strongly Agree</span>
                </div>
            </div>

            {showTyping && (
                <div className="typing-block" style={{ marginTop: '24rem',minHeight: typeHeight }}>
                    {showFisrtText && (
                        <TypeAnimation
                            sequence={[
                                fisrtText,
           
                            ]}
                            wrapper="span"
                            speed={80}
                            style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                            repeat={1}
                            cursor={false}
                        />
                    )}
                </div>
            )}



        
        </div>
    );
}

export default Statement;
