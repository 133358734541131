import React, { useState,  useEffect } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomRadio from '../components/UI/radio/CustomRadio';
import { TypeAnimation } from 'react-type-animation';
import CustomTitle from '../components/UI/title/CustomTitle';


const Inspiration = ({setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setButtonChoose, setCheckboxes,  setEventProperties, sessionId, setEventName }) => {

    useEffect(() => {
        setButtonShow(true)
        setButtonText('Continue')
        setButtonShow(true);
        setCheckboxes({
            "Generating ideas": true,
        })
        setSelectedOption(null)
        setButtonChoose(false);
        setEventName('web_inspiration')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);


    const initialCheckboxState = [
        {
            id: 'option6',
            checked: false,
            text: "Yes",
            text1: "Great! If you know what content your audience likes, it can help our AI improve your results even more.",
        },
        {
            id: 'option7',
            checked: false,
            text: "I want to learn more",
            text1: "Great! If you know what content your audience likes, it can help our AI improve your results even more.",
        }
    ];
    // const [CheckboxState, setCheckboxState] = useState(initialCheckboxState);

    // useEffect(() => {
    //     setButtonShow(true)
    //     setButtonText('Continue')
    //     setButtonShow(true);
    //     // setCheckboxes({
    //     //     "Generating ideas": true,

    //     // });
    //     setCheckboxState(initialCheckboxState)
    //     setSelectedOption(null)
        

    // }, []);




    const handleCheckboxChange = (checkbox) => {
        setShowFisrtText(false)
        setSelectedOption(checkbox.id);
        setShowTyping(true);
        setFisrtText(checkbox.text1)
        setTimeout(() => {
            setShowFisrtText(true)
        }, 100);
        setError(false);
    };

    const areAllCheckboxesUnchecked = () => {
        return selectedOption === null;
    };

    const [showTyping, setShowTyping] = useState(false);
    const [fisrtText, setFisrtText] = useState('');
    const [showFisrtText, setShowFisrtText] = useState(false);

    return (
        <div style={{paddingBottom: "18rem" }}>
            <CustomTitle style={{ marginBottom: "12rem" }} title="Do you know where to  discover best-performing creatives for inspiration? " />
            {initialCheckboxState.map((checkbox) => (
                <CustomRadio
                    key={checkbox.id}
                    id={checkbox.id}
                    checked={selectedOption === checkbox.id}
                    onChange={() => handleCheckboxChange(checkbox)}
                    className={error ? 'error' : undefined}
                    style={{ height: '42rem' }}
                >
                    {checkbox.text}
                </CustomRadio>
            ))}

            {/* {showTyping && (
                <div className="typing-block" style={{  marginTop: '16rem' }}>
                    {showFisrtText && (
                        <TypeAnimation
                            sequence={[
                                fisrtText,
                            ]}
                            wrapper="span"
                            speed={80}
                            style={{ fontSize: '12rem', display: 'block', fontWeight: "400" }}
                            repeat={1}
                            cursor={false}
                        />
                    )}
                </div>
            )} */}


{/* 
            <WithWizard
                render={({ next, previous, step, steps }) => (
                    <MyButton 
                    style={{ marginTop: "12rem" }}
                    onClick={() => {
                        if (areAllCheckboxesUnchecked()) {
                            setError(true);setTimeout(() => {
                            setError(false);
                        }, 1000);
                        } else {
                            setError(false);
                            next();
                        }
                    }} className={areAllCheckboxesUnchecked() ? (error ? 'error' : 'disable') : undefined}>
                        Continue
                        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.8901 3.54917C10.6197 3.81575 10.5944 4.23698 10.816 4.53253L10.8826 4.60981L15.4583 9.24992L3.75 9.24992C3.33579 9.24992 3 9.5857 3 9.99992C3 10.3823 3.28611 10.6978 3.65592 10.7441L3.75 10.7499H15.4583L10.8826 15.39C10.616 15.6604 10.5966 16.082 10.8224 16.3743L10.8901 16.4507C11.1605 16.7172 11.582 16.7366 11.8744 16.5108L11.9507 16.4431L17.7841 10.5265C18.048 10.2588 18.07 9.84232 17.8501 9.54985L17.7841 9.47336L11.9507 3.5567C11.6599 3.26173 11.1851 3.25837 10.8901 3.54917Z" fill="#5BF0A5" />
                        </svg>
                    </MyButton>
                )}
            /> */}
        </div>
    );
}

export default Inspiration;
