import React, { useState, useEffect, useRef } from 'react';
import './ai.scss'
import { TypeAnimation } from 'react-type-animation';
import { useHistory } from 'react-router-dom';
import ReactPlayer from 'react-player';
import mixpanel from 'mixpanel-browser';
const Ai2 = ({videoImg, videoSrc, timingInterval, videoShowStatus, handleClickAi, aiText, setButtonText, setSelectedOption, setButtonShow, setAiShow, setButtonStatus, setBlockPaddingBottom, setButtonChoose, setButtonTime, nextPage,setEventProperties, sessionId, setEventName, eventName }) => {
    const history = useHistory();

    const [videoShow, setVideoShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [secondType, setSecondType] = useState(false);
    useEffect(() => {
        setButtonText('Continue');
        setSelectedOption('1');
        setButtonShow(true);
        setAiShow(true);
        setButtonStatus("white");
        setBlockPaddingBottom('0');
        if (setButtonChoose) {
            setButtonChoose(false)
        }
        if (setButtonTime) {
            setButtonTime(150)
        }
        if (videoShowStatus) {
            setVideoShow(videoShowStatus)
            setLoading(videoShowStatus)
        }
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_utm_campaign: localStorage.getItem("utmCampaign"),
            web_utm_source: localStorage.getItem("utmSource"),
            web_utm_medium: localStorage.getItem("utmMedium"),
            web_utm_content: localStorage.getItem("utmContent"),
            web_utm_term: localStorage.getItem("utmTerm"),
            isDebug: false,
funnel: 'MAIN_v1',
        })
        setEventName(eventName)
    }, []);

    const [currentTextIndex, setCurrentTextIndex] = useState(0);
    const [widths, setWidths] = useState(new Array(aiText.length).fill(0));
    const [animationFinished, setAnimationFinished] = useState(new Array(aiText.length).fill(false));

    useEffect(() => {
       
        if (loading === false) {
            let time = 30;
            if (timingInterval) {
                time = timingInterval[currentTextIndex];
            }
            const interval = setInterval(() => {
                setWidths(prevWidths =>
                    prevWidths.map((width, i) =>
                        i === currentTextIndex ? Math.min(width + 1, 100) : width
                    )
                );
            }, time);
            return () => clearInterval(interval);
        }
    }, [currentTextIndex, loading]);

    useEffect(() => {
        if (widths[currentTextIndex] >= 100) {
            setAnimationFinished(prevFinished =>
                prevFinished.map((finished, i) =>
                    i === currentTextIndex ? true : finished
                )
            );
            if (currentTextIndex < aiText.length - 1) {
                setSecondType(false)
                setCurrentTextIndex(currentTextIndex + 1);
            } else {

                mixpanel.track(eventName, {
                    session_id: sessionId,
                    web_entrance: 'signup',
                    web_utm_campaign: localStorage.getItem("utmCampaign"),
                    web_utm_source: localStorage.getItem("utmSource"),
                    web_utm_medium: localStorage.getItem("utmMedium"),
                    web_utm_content: localStorage.getItem("utmContent"),
                    web_utm_term: localStorage.getItem("utmTerm"),
                    isDebug: false,
funnel: 'MAIN_v1',
                });
                if(eventName === 'web_demosite') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      'event': 'quiz_funnel1'
                    });
                }
                if(eventName === 'web_democreatives') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      'event': 'quiz_funnel2'
                    });
                }
                if(eventName === 'web_demovideos') {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                    'event': 'quiz_funnel3'
                    });
                }
                history.push(nextPage);

            }
        }
    }, [widths, currentTextIndex, aiText.length]);



    const handleTypingFisrtEnd = (index) => {
        setSecondType(true);
        setAnimationFinished(animationFinished.map((finished, i) => i === index ? true : finished));
    };

    const handleTypingEnd = (index) => {
        setAnimationFinished(animationFinished.map((finished, i) => i === index ? true : finished));
    };

    const [animationKey, setAnimationKey] = useState(0);
    const restartAnimation = () => {
        setAnimationKey(prevKey => prevKey + 1);
    };
    const videoRef = useRef(null);

    const handleLoadedData = () => {
        setLoading(false);
        console.log('Видео загружено');
    };


    const handleProgress = (state) => {
        
        if(state.playedSeconds > 0.001) {
            setLoading(false);
        }
    };

    const handleStart = () => {
        setLoading(false);
    };

    return (
        <div className="ai" style={{ width: '100%' }}>
            <div className="ai-top">
                {aiText.map((_, index) => (
                    <div key={index} className="ai-top-line">
                        <span style={{ width: `${widths[index]}%`, transition: 'width 20ms' }}></span>
                    </div>
                ))}
            </div>
            <div className="ai-content" style={{ width: '100%' }}>
                <div style={{ aspectRatio: "101/120", position: 'relative',width:'100%' }} className='ai-img'>
                    {videoShowStatus ? (
                        <>
                            {loading && (
                                <img style={{ position: 'absolute', top: "0", left: '0', zIndex: '1', width: '100%' }} src={process.env.PUBLIC_URL + `/img/${videoImg}`} alt="" />
                            )}
                            {/* <video
                                ref={videoRef}
                                style={{ width: '100%', borderRadius: '12rem' }}

                                autoPlay
                                loop
                                muted
                                playsInline
                                onLoadedData={handleLoadedData}
                                controls

                            >
                                <source src={process.env.PUBLIC_URL + `/video/Creative_builder_flow_to_web.mp4`} type="video/mp4" />
                            </video> */}
                            {/* <HLS url={process.env.PUBLIC_URL + `/video/Creative_builder_flow_to_web.mp4`} autoPlay controls /> */}
                            <ReactPlayer
                                width='100%'
                                height='auto'
                                loop={true}
                                playing={true}
                                playsinline={true}
                                volume={0.5}
                                muted={true}
                                // style={{opacity: opacity}}
                                onReady={(e) => {
                                    const player = e.getInternalPlayer();
                                    player.setAttribute('muted', 'true');
                      
                                 
                                }}
                                // onPlay={handleStart}
                                progressInterval={10} 
                                onProgress={handleProgress}
                  
                                url={process.env.PUBLIC_URL + `/video/${videoSrc}`} controls={false} />
                        </>
                    ) : (
                        <img src={process.env.PUBLIC_URL + '/img/ainew.webp'} alt="" />
                    )}

                </div>
                {aiText.map((text, index) => (
                    <>

                        {currentTextIndex === index && (
                            <div key={index} className={`ai-text`}>
                                <p>{text[0]}<br />{text[1]}</p>
                                {/* {!loading && ( */}
                                    <span className="ai-type">
                                        <div className={`${animationFinished[index] ? 'hide-cursor' : ''}`}>
                                            <TypeAnimation
                                                key={animationKey} // Use the state variable as the key
                                                sequence={[
                                                    text[0],
                                                    () => {
                                                        handleTypingFisrtEnd(index);
                                                    },
                                                ]}
                                                wrapper='p'
                                                speed={70}
                                                // style={{ fontSize: '26rem', fontWeight: "700" }}
                                                repeat={0}
                                            />
                                        </div>

                                        {secondType && (
                                            <TypeAnimation
                                                key={animationKey} // Use the state variable as the key
                                                sequence={[
                                                    text[1],
                                                    () => {
                                                        handleTypingEnd(index);
                                                    },
                                                ]}
                                                wrapper='p'
                                                speed={70}
                                                // style={{ fontSize: '26rem', fontWeight: "700" }}
                                                repeat={0}

                                            />
                                        )}

                                    </span>
                                {/* )} */}
                            </div>
                        )}
                    </>

                ))}
            </div>
        </div>
    );
};

export default Ai2;