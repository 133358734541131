import React, { useEffect, useState } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import CustomCheckbox from '../components/UI/chekbox/CustomCheckbox';
import CustomTitle from '../components/UI/title/CustomTitle';
import { TypeAnimation } from 'react-type-animation';


const Making = ({ setSelectedOption, setCheckboxes, checkboxes, setError, error, setButtonText, setButtonShow, setAiShow, setBlockPaddingBottom, setButtonStatus, setEventProperties, sessionId, setEventName }) => {

    const initialCheckboxState = {
        "Generating ideas": false,
        "Writing scripts": false,
        "Finding creators": false,
        "Video editing": false,
        "Something else": false,
    };

    useEffect(() => {
        setButtonText('Continue')
        setCheckboxes(initialCheckboxState);
        setButtonShow(true)
        setAiShow(false)
        setBlockPaddingBottom("32rem")
        setButtonStatus('')
        setSelectedOption('1')
        setEventName('web_difficult_2')
        
   
    }, []);

    const [showTyping, setShowTyping] = useState(false)

    const handleCheckboxChange = (checkboxName, isChecked) => {
        setCheckboxes((prevCheckboxes) => ({
            ...prevCheckboxes,
            [checkboxName]: Boolean(isChecked),
        }));
        setError(false);
        setShowTyping(true);
    };


    useEffect(() => {
        const trueCheckboxNames = Object.keys(checkboxes).filter(checkboxName => checkboxes[checkboxName]);

        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
            web_difficult_2_answer: trueCheckboxNames.join(', ')
        });
    }, [checkboxes]);

    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>
            <CustomTitle style={{ marginBottom: "12rem" }} title="What do you find difficult about making videos?" />
            {Object.keys(checkboxes).map((checkboxName) => (
                <CustomCheckbox
                    key={checkboxName}
                    style={{ height: '45rem' }}
                    checked={checkboxes[checkboxName]}
                    onChange={(isChecked) => handleCheckboxChange(checkboxName, isChecked)}
                    className={error ? 'error' : undefined}
                >

                    {checkboxName}
                </CustomCheckbox>
            ))}
            {showTyping && (
                <div className="typing-block" style={{  marginTop: '18rem', marginBottom: '18rem',minHeight: typeHeight }}>
                
                        <TypeAnimation
                            sequence={[
                                'AI will suggest ideas, write scripts, choose avatars, and edit videos – all with a focus on boosting sales!',
                            ]}
                            wrapper="span"
                            speed={80}
                            style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                            repeat={1}
                            cursor={false}
                        />
                    
                </div>
            )}
            <div style={{ paddingBottom: "18rem" }}></div>

        </>
    );
}

export default Making;
