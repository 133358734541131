import React, { useState, useEffect } from 'react';
import MyButton from '../components/UI/button/MyButton';
import { WithWizard } from 'react-albus-react18';
import { TypeAnimation } from 'react-type-animation';
import CustomTitle from '../components/UI/title/CustomTitle';

const Statement3 = ({setButtonText, setError, error, selectedOption, setSelectedOption, setButtonShow, setEventProperties, sessionId, setEventName}) => {
    useEffect(() => {
        setButtonText('Continue')
        setSelectedOption(null)
        setButtonShow(true)
        setEventName('web_target')
        setEventProperties({
            session_id: sessionId,
            web_entrance: 'signup',
        })
    }, []);

    const initialCheckboxState = [
        {
            id: 'option1',
            checked: false,
            text: "👎",
            text1: "Awesome news! We know how to reach your audience with ads and get them to buy from you.",
        },
        {
            id: 'option2',
            checked: false,
            text: "👎",
            text1: "Awesome news! We know how to reach your audience with ads and get them to buy from you.",
          
        },
        {
            id: 'option3',
            checked: false,
            text: "🤷",
            text1: "Awesome news! We know how to reach your audience with ads and get them to buy from you.",
           
        },
        {
            id: 'option4',
            checked: false,
            text: "👍",
            text1: "That's awesome! We're sure our AI will make it easier and cheaper to bring in target customers.",
        },
        {
            id: 'option5',
            checked: false,
            text: "👍",
            text1: "That's awesome! We're sure our AI will make it easier and cheaper to bring in target customers.",
        },
    ];


    const handleCheckboxChange = (checkbox) => {
        setSelectedOption(checkbox.id);
        setShowTyping(true);
        if(fisrtText != checkbox.text1) {
            setShowFisrtText(false)
            setFisrtText(checkbox.text1)
            setTimeout(() => {
                setShowFisrtText(true)
            }, 100);
        }

        setError(false);
    };

    const areAllCheckboxesUnchecked = () => {
        return selectedOption === null;
    };

    const [showTyping, setShowTyping] = useState(false);
    const [fisrtText, setFisrtText] = useState('');
    const [showFisrtText, setShowFisrtText] = useState(false);



    const [fontSize, setFontSize] = useState('12rem');
    const [typeHeight, setTypeHeight] = useState('58rem');

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 500) {
                setFontSize('14rem');
                setTypeHeight('63.6rem')
            } else {
                setFontSize('12rem');
                setTypeHeight('58rem')
            }
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div style={{ paddingBottom: '32rem' }}>
            <CustomTitle style={{ marginBottom: '12rem' }} title="“I know how to set up ads for my target audience.”" />
            <p className="text" style={{ marginBottom: '24rem' }}>
            Do you agree with the following statement?
            </p>
            <div className="emojiRadio">
                <div className="emojiRadio-wrapper">
                {initialCheckboxState.map((checkbox) => (
                    <label className={error ? 'error' : undefined}>
                        <input key={checkbox.id}
                            id={checkbox.id}
                            type="radio"
                            checked={selectedOption === checkbox.id}
                            onChange={() => handleCheckboxChange(checkbox)}
                        />
                        <div className="emojiRadio-item">
                            {checkbox.text}
                        </div>
                    </label>
                ))}
                </div>
                <div className="emojiRadio-bottom">
                    <span>Strongly Disagree</span>
                    <span>Strongly Agree</span>
                </div>
            </div>

            {showTyping && (
                <div className="typing-block" style={{ marginTop: '24rem',minHeight: typeHeight }}>
                    {showFisrtText && (
                        <TypeAnimation
                            sequence={[
                                fisrtText,
                            ]}
                            wrapper="span"
                            speed={80}
                            style={{ fontSize: fontSize, display: 'block', fontWeight: "400" }}
                            repeat={1}
                            cursor={false}
                        />
                    )}
                </div>
            )}

        </div>
    );
}

export default Statement3;
